<template>
  <v-container class="mt-4 ml-2" fluid>
	  <v-row v-if="( [18,19].includes( getdatosUsuario.idpuesto ) && [568, 7, 161, 626, 382, 1196 ].includes( getdatosUsuario.iderp ) ) || ![18,19].includes( getdatosUsuario.idpuesto )">
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Prospectos Reclutamiento</span>
            <v-spacer></v-spacer>

            <v-btn
              color="red"
              dark
              class="text-capitalize mr-2"
              @click="paramCerrados.estatus = true"
              small
              tile
            >
              <v-icon left small>mdi-delete</v-icon>
              Papalera
            </v-btn>
            
            <v-btn
              color="orange"
              dark
              class="text-capitalize mr-2"
              @click="colaboradores.estatus = true"
              small
              tile
            >
              <v-icon left small>mdi-account-multiple</v-icon>
              Colaboradores
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

		      <!-- Buscador y filtros -->
		      <v-card-text class="py-0">
		        <v-row class="pb-0">
		          <v-col cols="12" md="10" lg="9" class="pb-0">
		            <v-radio-group
		              v-model="estatusFiltro"
		              row
		            >
		              <v-badge
		                bordered
		                color="red"
		                overlap
		                :content="canAtrasados"
		                class="mr-10"
		              >
		                <v-radio
		                  label="Atrasado"
		                  :value="1"
		                  color="red"
		                ></v-radio>
		              </v-badge>

		              <v-badge
		                bordered
		                color="green"
		                overlap
		                :content="cantNuevos"
		                class="mr-10"
		              >
		                <v-radio
		                  label="Nuevo"
		                  :value="2"
		                  color="green"
		                ></v-radio>
		              </v-badge>

		              <v-badge
		                bordered
		                color="orange"
		                overlap
		                :content="cantSeguimiento"
		                class="mr-10"
		              >
		                <v-radio
		                  label="Seguimiento"
		                  :value="3"
		                  color="orange"
		                ></v-radio>
		              </v-badge>

		              <v-badge
		                bordered
		                color="black"
		                overlap
		                :content="cantTodas"
		              >
		                <v-radio
		                  label="Todos"
		                  :value="6"
		                  color="black"
		                ></v-radio>
		              </v-badge>
		            </v-radio-group>
		          </v-col>

		          <v-col cols="12" sm="6"md="2" lg="3" xl="2" class="pb-0">
		            <v-text-field
		              v-model="search"
		              dense
		              outlined
		              label="Buscar"
		              append-icon="mdi-magnify"
		            ></v-text-field>  
		          </v-col>
		        </v-row>
		      </v-card-text>

		      <v-card-text class="pt-0 pb-0" v-if="estatusFiltro != 6">
		        <div v-if="estatusFiltro == 1 || estatusFiltro == 2">
		          <v-alert
		            border="left"
		            colored-border
		            type="info"
		            elevation="2"
		          >
		            <div v-if="estatusFiltro == 1">
		              Para cumplir con la etapa, es necesario cumplir con los siguientes criterios:<br/>
		              1-. Ingresaron hace más de 1 día<br/>
		              2-. No se le ha brindado información<br/>
		              3-. No tiene tareas asignadas
		            </div>

		            <div v-if="estatusFiltro == 2">
		              Para cumplir con la etapa, es necesario cumplir con los siguientes criterios:<br/>
		              1-. Ingresaron el día de hoy<br/>
		              2-. No se le ha brindado información<br/>
		              3-. No tiene tareas asignadas
		            </div>
		          </v-alert>
		        </div>

		        <v-row v-if="estatusFiltro == 3 || estatusFiltro == 4 || estatusFiltro == 5">
		          <v-col cols="12" md="6" lg="4">
		            <v-alert
		              border="left"
		              colored-border
		              type="info"
		              elevation="2"
		            >
		              <strong>INDUCCIÓN | {{ cantInduccion }} |</strong><br/>
		              Citerios:<br/>
		              1-. El prospecto se encuentra en inducción<br/>
		            </v-alert>
		          </v-col>

		          <v-col cols="12" md="6" lg="4">
		            <v-alert
		              border="left"
		              colored-border
		              type="info"
		              elevation="2"
		            >
		              <strong>Seg. con información | {{ cantConInfo }} |</strong><br/>
		              Criterios:<br/>
		              1-. El prospecto ya cuenta con información<br/>
		            </v-alert>
		          </v-col>

		          <v-col cols="12" md="6" lg="4">
		            <v-alert
		              border="left"
		              colored-border
		              type="info"
		              elevation="2"
		            >
		              <strong>Seg. sin información | {{ cantSinInfo }} |</strong><br/>
		              Criterios:<br/>
		              1-. El prospecto no cuenta con información<br/>
		            </v-alert>
		          </v-col>
		        </v-row>
		      </v-card-text>  

		      <!-- Tablas -->
		      <v-card-text class="pt-0">
		        <v-tabs
		          dense
		          v-model="tab"
		        >
		          <v-tabs-slider></v-tabs-slider>

		          <v-tab dense class="text-capitalize">
		            <v-icon left>mdi-account</v-icon>Sin seguimiento | {{ filterProspectos.length }} |
		          </v-tab>

		          <v-tab dense class="text-capitalize">
		            <v-icon left>mdi-account</v-icon>Con seguimiento | {{ filterProspectosSeguimiento.length }} |
		          </v-tab>

		          <v-tab dense class="text-capitalize">
		            <v-icon left>mdi-account</v-icon>Tarea Agendada | {{ filterProspectosTareas.length }} |
		          </v-tab>

		          <!-- Apartado de las secciones de los tabs -->
		          <v-tabs-items v-model="tab">

		            <!-- Sin seguimiento -->
		            <v-tab-item>
		              <v-col cols="12" lg="12" class="pl-0">
		                <v-card class="elevation-0" >
		                  <div>
		                    <v-subheader class="pl-0">
		                      <v-btn color="success" smal icon @click="exportarContactos()"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
		                      <strong>Progreso {{ progreso }} %</strong>
		                    </v-subheader>
		                    <v-progress-linear
		                      :value="progreso"
		                      height="5"
		                      rounded
		                      readonly
		                    >
		                    </v-progress-linear>
		                  </div>

		                  <TablasFiltros :items="filterProspectos" :headers="headers" :search="search" @verProspecto="verProspecto" @verPDF="verPDF"/>

		                </v-card>
		              </v-col>
		            </v-tab-item>

		            <!-- CON SEGUIMIENTO -->
		            <v-tab-item>
		              <v-col cols="12" lg="12" class="pl-0">
		                <v-card class="elevation-0" >

		                	<TablasFiltros :items="filterProspectosSeguimiento" :headers="headers" :search="search" @verProspecto="verProspecto" @verPDF="verPDF"/>

		                </v-card>
		              </v-col>
		            </v-tab-item>

		            <!-- Con seguimiento -->
		            <v-tab-item>
		              <v-col cols="12" lg="12" class="pl-0">
		                <v-card class="elevation-0">
		                	<TablasFiltros :items="filterProspectosTareas" :headers="headers" :search="search" @verProspecto="verProspecto" @verPDF="verPDF"/>
		                </v-card>
		              </v-col>
		            </v-tab-item>
		          </v-tabs-items>
		        </v-tabs>
		      </v-card-text> 
        </v-card>
      </v-col>
    </v-row>

    <!-- dialog del prospecto -->
    <v-dialog
      v-if="prospecto"
      v-model="dialogProspecto"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card
        :loading="loading"
      >
        <template slot="progress">
          <v-progress-linear
            color="secondary"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-toolbar
          dark
          color="primary"
          class="elevation-1"
        >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="anterior()"
              v-if="estatusLlamada == 0"
            >
              <v-icon>mdi-arrow-left-bold</v-icon>
            </v-btn>

            <v-btn
              icon
              dark
              @click="siguiente()"
              v-if="estatusLlamada == 0"
            >
              <v-icon>mdi-arrow-right-bold</v-icon>
            </v-btn>
            
            <v-btn
              icon
              dark
              @click="dialogProspecto = false, initialize()"
              v-if="estatusLlamada == 0"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <!-- Datos principales del usuario -->
        <v-card-text class="black--text">
          <v-row>
            <!-- Datos del usuario -->
            <v-col cols="12" lg="6">

              <!-- Eventos en timeline -->
              <v-card-text class="pt-4 pb-0">
                <v-subheader class="pl-0">Eventos</v-subheader>
                <div v-if="!eventosTimeline.length">Sin eventos</div>
                <Eventos v-else :eventosTimeline="eventosTimeline"/>
              </v-card-text>

              <v-card-text class="pt-12">
                <v-row>
                  <!-- Datos personales -->
                  <v-col cols="12" class="pb-0">
                    <DatosPersonales :prospecto="prospecto" class="pb-2"/>
                    <Etapas          :idetapa="idetapa"/>
                    <DetalleEvento   :prospecto="prospecto"/>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <!-- Botones de accion -->
              <v-card class="elevation-0">
                <v-card-text>
                  <v-row>
                    <!-- Boton para finalizar prospecto -->
                    <v-col>
                      <v-card
                        color="red"
                        dark
                        @click="dialogFinalizacion = true"
                        class="text-center mr-2 shadowCard"
                        max-width="200"
                      >
                        <v-card-text class="py-1">
                          <div class="white--text text-h5">
                            <v-icon
                              size="22"
                              color="white"
                              v-text="'mdi-account-check'"
                            >
                            </v-icon>                                                                                                                                                                                                                                                                                                    
                          </div>
                          <sub class="text-caption white--text ">Finalizar</sub>
                        </v-card-text>
                      </v-card>
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>

              <v-divider></v-divider>
            </v-col>

            <!-- TABS DE SEGUIMIENTO -->
            <v-col cols="12" md="12" lg="6">
              <v-tabs
                color="secondary"
                right
                dense
                class="elevation-0"
              >
                <v-tab class="text-capitalize">Comentarios</v-tab>
                <v-tab class="text-capitalize">CV</v-tab>
                <v-tab class="text-capitalize">Tareas | {{ eventosTimeline.length }} |</v-tab>

                <!-- Primer TAB INFROMACIÓN -->
                <!-- Este tab solo se habilitará si no es primer contacto -->
                <v-tab-item class="pt-0">
                  <v-row class="mt-4">
                    <!-- Pregunta #1 -->
                    <v-col cols="12">
                      <Comentario      :comentarios="comentarios" @click="addComentario"/>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item class="pt-0">
                  <v-row class="mt-4">
                    <!-- Pregunta #1 -->
                    <v-col cols="12">
                			<embed :src="url_servidor + prospecto.cv" type="application/pdf" width="100%" height="600px" />
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- Tab de tareas programados -->
                <v-tab-item class="pt-0">
                  <v-row>
                    <v-col cols="12">
                      <v-card class="elevation-0">
                        <v-card-title primary-title>
                          <v-spacer></v-spacer>
                          <v-btn color="secondary" fab small @click="opcionAddTarea = !opcionAddTarea, banderaTarea = 0, disableBtnTarea = false" v-if="tareas_programadas.length == 0">
                            <v-icon v-if="!opcionAddTarea">mdi-plus</v-icon>
                            <v-icon v-else>mdi-window-minimize</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text v-if="opcionAddTarea">
                          <v-row>
                            <v-col cols="12" lg="4">
                              <v-select
                                :items="tareas"
                                v-model="tarea"
                                label="Tarea"
                                dense
                                hide-details
                                item-value="idtareas"
                                item-text="tarea"
                                return-object
                              ></v-select>
                            </v-col>

                            <v-col cols="12"  md="6" lg="4">
                              <v-text-field
                                v-model="dia"
                                label="Día"
                                dense
                                type="date"
                                hide-details
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4">
                              <v-select
                                :items="[{id: 1, tipo:'Agendada'},{id: 2, tipo:'Nota'}]"
                                v-model="tipo_tarea"
                                label="Tipo de tarea"
                                dense
                                hide-details
                                item-value="id"
                                item-text="tipo"
                                return-object
                                readonly
                              ></v-select>
                            </v-col>

                            <v-col cols="12"  md="6" lg="4" v-if="tipo_tarea && tipo_tarea.id == 2">
                              <v-select
                                :items="horaPrecargada"
                                v-model="horaCargada"
                                label="Hora"
                                dense
                                hide-details
                                item-value="id"
                                item-text="hora"
                                return-object
                                clearable
                              ></v-select>
                            </v-col>

                            <v-col cols="12"  md="6" lg="4" v-if="horaCargada && horaCargada.id == 5 || tipo_tarea && tipo_tarea.id == 1">
                              <v-text-field
                                v-model="hora"
                                label="Hora"
                                dense
                                type="time"
                                hide-details
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4">
                              <v-select
                                :items="motivos"
                                v-model="motivo"
                                label="Motivo"
                                dense
                                hide-details
                                item-value="idmotivos"
                                item-text="motivo"
                                return-object
                              ></v-select>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" v-if="motivo && motivo.motivo == 'Otro'">
                              <v-text-field
                                v-model="descrip_motivo"
                                label="Descripción"
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            
                            <v-col cols="12">
                              <!-- :disabled="disableBtnTarea" -->
                              <v-btn color="primary" block @click="programarTarea()"   v-if="banderaTarea == 0 && motivo && tipo_tarea">Promagar tarea</v-btn>
                              <v-btn color="primary" block @click="reprogramarTarea()" v-if="banderaTarea == 1">Reprogramar tarea</v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <!-- Tareas programadas -->
                        <v-card-text>
                          <div v-for="(tarea, i) in tareas_programadas" :key="i">
                            <div class="text-body-1">
                              <v-icon :color="tarea.cumplida == 1 ? 'red' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
                              {{ tarea.motivo }}<br/>
                              <span v-if="tarea.idmotivos == 7">{{ tarea.descrip_motivo }}</span>
                            </div>
                            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>

                            <v-chip-group
                              column
                            >
                              <!-- Copiar el telefono -->
                              <v-tooltip
                                v-if="tarea.tarea_tipo == 1"
                                top
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dialogTareasProgramadas = false, intentoLlamada = 1, dialogSeguimiento = true"
                                    rounded
                                    small><v-icon>mdi-phone</v-icon>
                                  </v-chip>
                                </template>
                                <span>Click para iniciar</span>
                              </v-tooltip>

                              <!-- Mensaje de whatsapp -->
                              <v-tooltip
                                v-if="tarea.tarea_tipo == 2"
                                top
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dialogTareasProgramadas = false, intentoLlamada = 3, dialogSeguimiento = true"  
                                    rounded
                                    small><v-icon>mdi-whatsapp</v-icon>
                                  </v-chip>
                                </template>
                                <span>Click para iniciar</span>
                              </v-tooltip>

                              <!-- Eliminar -->
                              <v-tooltip
                                top
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip color="error"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="eliminarTarea(tarea.idtareas_reclutadora)" 
                                    rounded
                                    small><v-icon>mdi-delete</v-icon>
                                  </v-chip>
                                </template>
                                <span>Eliminar tarea</span>
                              </v-tooltip>

                              <!-- Reprogramar -->
                              <v-tooltip
                                top
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip color="warning"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="actualizarTarea(tarea),disableBtnTarea = false" 
                                    rounded
                                    small><v-icon>mdi-refresh</v-icon>
                                  </v-chip>
                                </template>
                                <span>Reprogramar</span>
                              </v-tooltip>

                            </v-chip-group>
                            <v-divider></v-divider>
                          </div>

                          <v-subheader>Tareas realizadas</v-subheader>
                          <div v-for="tarea in tareas_realizadas">
                            <div class="text-body-1">
                              <v-icon :color="tarea.cumplida == 1 ? 'green' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
                              {{ tarea.motivo }}<br/>
                              <span v-if="tarea.idmotivos == 7">{{ tarea.descrip_motivo }}</span>
                            </div>
                            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>
                            <v-divider></v-divider>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>

              </v-tabs>

              <v-divider class="mt-8"></v-divider>
              <span class="black--text text-subtitle-1 font-weight-bold mt-4">ETAPA ACTUAL DEL PROSPECTO: </span>
              <br/>

              <v-select
                :items="etapasProspectos"
                v-model="prospecto.etapa"
                item-text="text"
                item-value="etapa"
                filled
              ></v-select>
              <br/>
              <br/>


              <span class="black--text text-subtitle-1 font-weight-bold mt-4">Siguiente Etapa: </span>
              <br/>
              <span class="black--text font-weight-bold text-h5">{{ etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).text : ''  }}</span>
              <br/>
              <embed 
              	:src="url_pdf + 'PRIMERA_ENTREVISTA.pdf'"     
              	type="application/pdf" 
              	width="100%" 
              	height="600px" 
              	v-if="etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).etapa == 1 ? true : false : false "
              />
              <embed 
              	:src="url_pdf + 'PRUEBA_TECNICA.pdf'"         
              	type="application/pdf" 
              	width="100%" 
              	height="600px" 
              	v-if="etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).etapa == 2 ? true : false : false "
              />
              <embed 
              	:src="url_pdf + 'PRUEBAS_PSICOMETRICAS.pdf'"  
              	type="application/pdf" 
              	width="100%" 
              	height="600px" 
              	v-if="etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).etapa == 3 ? true : false : false "
              />
              <embed 
              	:src="url_pdf + 'ENTREVISTA_TECNICA.pdf'"     
              	type="application/pdf" 
              	width="100%" 
              	height="600px" 
              	v-if="etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).etapa == 4 ? true : false : false "
              />
              <embed 
              	:src="url_pdf + 'INVESTIGACION_LABORAL.pdf'"  
              	type="application/pdf" 
              	width="100%" 
              	height="600px" 
              	v-if="etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).etapa == 5 ? true : false : false "
              />
              <embed 
              	:src="url_pdf + 'EXAMENES_MEDICOS.pdf'"       
              	type="application/pdf" 
              	width="100%" 
              	height="600px" 
              	v-if="etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).etapa == 6 ? true : false : false "
              />
              <embed 
              	:src="url_pdf + 'FIRMA_CONTRATO.pdf'"         
              	type="application/pdf" 
              	width="100%" 
              	height="600px" 
              	v-if="etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).etapa == 7 ? true : false : false "
              />
              <embed 
              	:src="url_pdf + 'INDUCCION_CAPACITACION.pdf'" 
              	type="application/pdf" 
              	width="100%" 
              	height="600px" 
              	v-if="etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).etapa == 8 ? true : false : false "
              />
              <embed 
              	:src="url_pdf + 'INDUCCION_CAPACITACION.pdf'" 
              	type="application/pdf" 
              	width="100%" 
              	height="600px" 
              	v-if="etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).etapa == 9 ? true : false : false "
              />
              <embed 
              	:src="url_pdf + 'INCORPORACION.pdf'"          
              	type="application/pdf" 
              	width="100%" 
              	height="600px" 
              	v-if="etapasProspectos.find( el => el.etapa == prospecto.etapa + 1) ? etapasProspectos.find( el => el.etapa == prospecto.etapa + 1).etapa == 10 ? true : false : false "
              />

            </v-col>
          </v-row>
        </v-card-text>

        <!-- Boton para agregar mensajes al seguimiento -->
        <v-btn
          @click="abrirDialogNotaLlamada()"
          fixed
          bottom
          right
          fab
          dark
          color="primary"
          class="mb-12"
          v-if="estatusLlamada"
        >
          <v-icon >mdi-message</v-icon>
        </v-btn>

        <!-- Boton para poder ver el tiempo transcurrido -->
        <v-btn
          @click="abrirDialogNotaLlamada()"
          fixed
          top
          right
          fab
          dark
          :color="alertaTiempo ? 'red' : 'primary' "
          style="margin-top: 100px;"
          v-if="showTimer"
        >
          {{ tiempo }}
        </v-btn>

        <!-- Dialog de carga -->
        <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
        <carga                v-if="cargar"/>

      </v-card>
    </v-dialog>

    <!-- Dialog de seguimiento dialogSeguimiento -->
    <v-dialog
      v-model="dialogSeguimiento"
      persistent
      max-width="800"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between">

            <v-col cols="12" sm="4" lg="5">
              <v-card class="elevation-0">
                <v-img src="@/assets/primerContacto.png">
                </v-img>
              </v-card>
            </v-col>

            <v-col cols="12" sm="8" lg="6">
              <v-card class="elevation-0 transparent" height="100%" style="display: table;" align="center">
                <div style="display: table-cell; vertical-align: middle; width: 100%;">
                  <div class="text-subtitle-1">
                    <strong class="text-h6" v-if="intentoLlamada == 0">Empecemos....</strong><br/> 
                    <br/>
                    <!-- Aquí validamos si la segunda llamada y el whatsapp están des habilidatos -->
                    <strong class="text-h6" v-if="intentoLlamada == 1">Realizar llamada por teléfono</strong>
                    <!-- Aquí validamos si la primer llamada  y el whatsapp están des habilidatos -->
                    <strong class="text-h6" v-if="intentoLlamada == 2">Realizar llamada por whatsApp</strong>
                    <!-- Aquí validamos si la primer llamada  y el whatsapp están des habilidatos -->
                    <strong class="text-h6" v-if="intentoLlamada == 3">Por favor, envía un mensaje de WhatsApp</strong>
                  </div>

                  <!-- Botones iniciales de primer llamada -->
                  <v-card-actions class="pt-6" v-if="intentoLlamada == 0">
                    <v-spacer></v-spacer>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" dar large class="mr-2 text-capitalize" @click="intentoLlamada++">Iniciar Llamada</v-btn>
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-tooltip
                      v-model="showTooltipTelefono"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="copiarTelefono(prospecto.telefono), showTooltipTelefono = !showTooltipTelefono">Copiar teléfono</v-btn>
                      </template>
                      <span>Teléfono copiado</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                  </v-card-actions>

                  <!-- si el intento de llamada es 1 o 2, se puede visualizar esta sección -->
                  <span v-if="intentoLlamada == 1 || intentoLlamada == 2" class="pt-6">
                    <br/>
                    <!-- Este titulo solo debe aparecer en las primeras dos llamadas -->
                    <div class="text-title" v-if="intentoLlamada < 3"><strong>¿Contestó y esta disponible para atenderte?</strong></div>
                    <!-- Este también solo debe aparecer en las primeras dos llamadas -->
                    <v-card-actions  class="pt-6" v-if="intentoLlamada < 3">
                      <v-spacer></v-spacer>
                      <!-- Si es si, cerramos este dialogo y abrimos el dialogo de info del usuario -->
                      <v-btn
                        color="error"
                        @click="dialogSeguimiento = false, updateEstatusLlamada(), addIntentoLlamada(11)"
                        class="text-capitalize"
                        v-if="intentoLlamada < 3 && intentoLlamada == 1"
                      >
                        Si
                      </v-btn>

                      <v-btn
                        color="error"
                        @click="dialogSeguimiento = false, updateEstatusLlamada(), addIntentoLlamada(12)"
                        class="text-capitalize"
                        v-if="intentoLlamada < 3 && intentoLlamada == 2"
                      >
                        Si
                      </v-btn>


                      <v-spacer></v-spacer>

                      <!-- No contesto llamada, llamar por whatsApp -->
                      <v-btn
                        color="#5C5C5C"
                        dark
                        @click="intentoLlamada++" 
                        v-if="intentoLlamada == 1"
                        class="text-capitalize"
                      >
                        No
                      </v-btn>

                      <!-- No contesto por Llamada de WhatsApp, mandar mensaje de WhatsApp -->
                      <v-btn
                        color="#5C5C5C"
                        dark
                        @click="intentoLlamada++"
                        v-if="intentoLlamada == 2"
                        class="text-capitalize"
                      >
                        No
                      </v-btn>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                    
                    <!-- Boton para copiar el teléfono, solo estará activo si es primerLlamada o segunda -->
                    <v-card-actions v-if="intentoLlamada > 0">
                      <!-- Guardar la información  -->
                      <v-tooltip
                        v-model="showTooltipTelefono"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn block color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="copiarTelefono(prospecto.telefono), showTooltipTelefono = !showTooltipTelefono">Copiar teléfono</v-btn>
                        </template>
                        <span>Teléfono copiado</span>
                      </v-tooltip>
                    </v-card-actions>
                  </span>

                  <!-- Mensaje de whatsApp -->
                  <span v-if="intentoLlamada == 3">
                    <!-- Abrir campo para whatsapp -->
                    <v-card-text>
                      <v-textarea
                        v-model="mensajeWhatsapp"
                        outlined
                        dense
                        rows="3"
                        hide-details
                        label="Escribir mensaje"
                      >
                      </v-textarea>
                    </v-card-text>

                    <!-- Botondes de WhatsApp -->
                    <v-card-actions v-if="mensajeWhatsapp != ''">
                      <v-spacer></v-spacer>
                      <!-- Enviar mensaje -->
                      <v-btn color="primary" 
                        @click="enviarWhatsApp()" 
                      >Enviar</v-btn>
                    </v-card-actions>
                  </span>

                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn 
          absolute
          fab
          text
          small
          top
          right
          class="mt-6"
          v-if="intentoLlamada < 3"
          @click="dialogSeguimiento = false, intentoLlamada = 0"
        ><v-icon>mdi-close</v-icon></v-btn>

        <v-btn 
          absolute
          fab
          text
          small
          top
          left
          class="mt-6"
          v-if="intentoLlamada > 0"
          @click="intentoLlamada--"
        ><v-icon>mdi-arrow-left-bold</v-icon></v-btn>

        <!-- Dialog de carga -->
        <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
        <carga                v-if="cargar"/>

      </v-card>
    </v-dialog>

    <!-- Dialog para tareas programadas -->
    <v-dialog
      v-model="dialogTareasProgramadas"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-h5">
          <span>Tareas pendientes</span>
        </v-card-title>
        <!-- Prospecto con pendientes -->
        <v-card-text>
          <div v-for="(tarea, i) in tareas_programadas" :key="i">
            <div class="text-body-1">
              <v-icon :color="tarea.cumplida == 1 ? 'red' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
              {{ tarea.motivo }}<br/>
              <span v-if="tarea.idmotivos == 7">{{ tarea.descrip_motivo }}</span>
            </div>
            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>

            <!-- Tareas programadas -->
            <v-chip-group column>

              <!-- Iniciar llamada -->
              <v-tooltip v-if="tarea.tarea_tipo == 1" top>
                <!-- Este es llamada por teléfono, entonces, lo que haremos es abrir el dialogo de seguimiento, cerrar este dialogo yyyy abrirlo directamente en llamada -->
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogTareasProgramadas = false, intentoLlamada = 1, dialogSeguimiento = true"  
                    rounded
                    small><v-icon>mdi-phone</v-icon>
                  </v-chip>
                </template>
                <span>Click para inciar</span>
              </v-tooltip>

              <!-- Mensaje de whatsapp -->
              <v-tooltip v-if="tarea.tarea_tipo == 2" top>
                <!-- Este es llamada por teléfono, entonces, lo que haremos es abrir el dialogo de seguimiento, cerrar este dialogo yyyy abrirlo directamente en whatsApp -->
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="green"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogTareasProgramadas = false, intentoLlamada = 3, dialogSeguimiento = true"  
                    rounded
                    small><v-icon>mdi-whatsapp</v-icon>
                  </v-chip>
                </template>
                <span>Enviar mensaje</span>
              </v-tooltip>

              <!-- Eliminar -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="error"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="eliminarTarea(tarea.idtareas_prospectos)" 
                    rounded
                    small><v-icon>mdi-delete</v-icon>
                  </v-chip>
                </template>
                <span>Eliminar tarea</span>
              </v-tooltip>

              <!-- Reprogramar -->
              <v-tooltip top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="warning"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="actualizarTarea(tarea),disableBtnTarea = false" 
                    rounded
                    small><v-icon>mdi-refresh</v-icon>
                  </v-chip>
                </template>
                <span>Reprogramar</span>
              </v-tooltip>
            </v-chip-group>
          </div>
        </v-card-text>

        <v-btn 
          absolute
          fab
          text
          small
          top
          right
          class="mt-6"
          @click="dialogTareasProgramadas = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </v-card>
    </v-dialog>

    <!-- Dialog de Finalización -->
    <v-dialog
      v-model="dialogFinalizacion"
      persistent
      max-width="800"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between" v-if="!banderaFinalizo && !banderaRechazo">
            <v-col cols="12" lg="6">
              <v-img src="@/assets/finalizo.png">
              </v-img>
            </v-col>
            <v-col cols="12" lg="6">
              <v-card class="elevation-0" height="100%" style="display: table;" align="center">
                <div style="display: table-cell; vertical-align: middle;">
                  <div class="text-subtitle-1"><strong class="text-h6">Que no cunda el pánico</strong><br/>La vacante se cerró con éxito? </div>
                  <v-card-actions class="pt-6">
                    <v-spacer></v-spacer>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="black" tile dark large class="mr-2 text-capitalize" @click="banderaRechazo = true">NO :(</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#03BF00" tile dark class="elevation-6 text-capitalize" large @click="banderaFinalizo = true">Sí :)</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!-- Finalizar, todo Ok -->
          <v-card-text v-if="banderaFinalizo">
            <div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
            <v-text-field
              label="Nombres"
              dense
              filled
              dense
              v-model="nombres"
            ></v-text-field>
            <v-text-field
              label="Apellido Paterno"
              dense
              filled
              dense
              v-model="apellido_paterno"
            ></v-text-field>
            <v-text-field
              label="Apellido Materno"
              dense
              filled
              dense
              v-model="apellido_materno"
            ></v-text-field>
          </v-card-text>

          <v-card-actions v-if="banderaFinalizo">
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn 
            	color="black" 
            	dark 
            	tile
            	class="mr-2 text-capitalize" 
            	@click="banderaFinalizo = false"
            >
            	<v-icon left small>mdi-cancel</v-icon>
          		Cancelar
          	</v-btn>
            <v-spacer></v-spacer>
            <!-- Guardar la información  -->
            <v-btn 
            	color="green" 
            	dark 
            	tile
            	class="elevation-6 text-capitalize"
            	@click="finalizarProspecto()"
            >
            	<v-icon left small>mdi-content-save</v-icon>
          		Guardar
          	</v-btn>
          </v-card-actions>

          <!-- Rechazar con motivo -->
          <v-card-text v-if="banderaRechazo">
            <div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
            <v-textarea
              v-model="notaRechazo"
              label="Motivo"
              dense
              outlined
              hide-details
            ></v-textarea>
          </v-card-text>
          <v-card-actions v-if="banderaRechazo">
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn 
            	color="black" 
            	dark 
            	tile
            	class="mr-2 text-capitalize" 
            	@click="banderaRechazo = false"
            >
            	<v-icon left small>mdi-cancel</v-icon>
          		Cancelar
          	</v-btn>
            <v-spacer></v-spacer>
            <!-- Guardar la información  -->
            <v-btn 
            	color="green" 
            	dark 
            	tile
            	class="elevation-6 text-capitalize"
            	@click="rechazoProspecto()"
            >
            	<v-icon left small>mdi-content-save</v-icon>
          		Guardar
          	</v-btn>
          </v-card-actions>
        </v-card-text>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogFinalizacion = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="" style="display: table-cell; vertical-align: middle;">
                  <strong>¡Bien!</strong> Datos grabados correctamente
                  <v-card-actions class="pt-6">
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/correcto.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog cerrados y/o finalizados -->
    <BasuraReclutadora
      v-if="paramCerrados.estatus"    
      :paramCerrados="paramCerrados"
      :prospectosCerrados="prospectosCerrados"
      @initialize="initialize"
    /> 

    <!-- Dialog de colaboradores -->
    <Colaboradores
      v-if="colaboradores.estatus"    
      :colaboradores="colaboradores"
    /> 

    <!-- Eliminar una tarea -->
    <DialogDeleteTarea    
      v-if="paramDeleteTarea.estatus"    
      :parametros="paramDeleteTarea" 
      @click="deleteItemConfirm"
    />

    <!-- VER EL CV DEL PROSPECTO -->
    <VerCv    
      v-if="dialogCV.estatus"    
      :parametros="dialogCV" 
      :prospecto="prospecto"
      @click="deleteItemConfirm"
    />

    <!-- Ver formulario -->
    <VerFormulario    
      v-if="dialogFormularios.estatus"    
      :dialogFormularios="dialogFormularios" 
      @click="deleteItemConfirm"
    />

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import DatosPersonales     from "@/components/formularios/DatosPersonales.vue";
import Etapas              from '@/components/prospectos/Etapas.vue';
import DetalleEvento       from '@/components/prospectos/DetalleEvento.vue';
import Comentario          from '@/components/formularios/Comentarios.vue';
import DialogDeleteTarea   from '@/components/prospectos/DialogDeleteTarea.vue';
import Eventos             from '@/components/prospectos/Eventos.vue';
import VerCv               from '@/components/formularios/VerCv.vue';
import VerFormulario       from '@/views/operaciones/rh/crm_rh/Formulario.vue'
import TablasFiltros       from '@/components/formularios/TablasFiltros.vue'
import BasuraReclutadora   from '@/components/formularios/BasuraReclutadora.vue'
import Colaboradores       from '@/components/formularios/Colaboradores.vue'

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

import cronometro      from '@/mixins/cronometro'
import funciones       from '@/mixins/rh/funciones'
import catalogos       from '@/mixins/rh/catalogos'

var moment  = require('moment');
moment.locale(); 

export default {
  mixins: [validarErrores, funcionesExcel, funciones, cronometro, catalogos],

  components: {
    Alerta,
    carga,
    DatosPersonales,
    Etapas,
		DetalleEvento,
		Comentario,
		Eventos,
		DialogDeleteTarea,
		VerCv,
		VerFormulario,
		TablasFiltros,
		BasuraReclutadora,
		Colaboradores
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialogCV: {
    	estatus: false
    },

    dialogFormularios:{
    	estatus: false
    },

    colaboradores:{
    	estatus: false
    },

    nombres:'',
		apellido_paterno:'',
		apellido_materno:'',

    etapasProspectos:[
			{ etapa: 0,  text: "Sin contacto"      },
			{ etapa: 1,  text: "1era entrevista"   },
	    { etapa: 2,  text: "Prueba Técnica"    },
	    { etapa: 3,  text: "Prueba psicológica"},
	    { etapa: 4,  text: "Entrevista Técnica"},
	    { etapa: 5,  text: "Inv. Laboral"      },
	    { etapa: 6,  text: "Exámenes Médicos"  },
	    { etapa: 7,  text: "Firma de contrato" },
	    { etapa: 8,  text: "Inducción"         },
	    { etapa: 9,  text: "Capacitación"      },
	    { etapa: 10, text: "Incorporación"     },
    ],


    plantel: null,
    planteles: [],
    puestos: [],
    medios: [],
    perfiles: [],
    trabajadores: [],
    formulario: [],
    usua: [],
    addcvfiles: [],
    addporfiles: [],
    addcvfiles2: [],
    addporfiles2: [],
    vistaPreviasCV: [],
    vistaPreviasPOR: [],
    fotosCV: [],
    fotosPOR: [],
    valor: 0,
    img: null,
    url_servidor: "",
    mensajeRechazo: "",

    dialog_agregar: false,
    dialog_editar: false,
    dialogDelete: false,
    dialog: false,
    dialogConfirmacion: false,
    dialogRechazar: false,

    editedIndex: -1,

    editedItem: {
      idformulario: 0,
    },

    defaultItem: {
      nombre_completo: "",
    },

    cleanItem: {
      idformulario: 0,
    },

    // Tareas del prospecto
    paramDeleteTarea:{
      estatus: false
    },

    // Reasignar
    paramReasignar:{
      estatus: false
    },

    // Filtros:
    incio: null,
    fin: null,

    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    horarios: [],
    headers: [
      { text: "ID"                , value: "idformulario"        },
      { text: "Nombre"            , value: "nombre_completo"     },
      { text: "Telefono"          , value: "numero_wa"           },
      { text: "Puesto"            , value: "puesto"              },
      { text: "Plantel"           , value: "plantel"             },
      { text: 'Eventos'           , value: 'cantEventos',        },
		  { text: 'E. Hoy'            , value: 'cantEventosHoy',     },
		  { text: 'Llamada'           , value: 'intentoLlamada',     },
		  { text: 'Etapa'             , value: 'idetapa'             },
		  { text: 'Etapa Actual'      , value: 'etapa'               },
		  { text: 'Sig. Tarea'        , value: 'tareasP'             },
		  { text: 'Fecha'             , value: 'fecha'               },
		  { text: 'T. transucrrido'   , value: 'tiempo_transcurrido' },
      { text: "Acciones"          , value: "actions", sortable: false },
    ],

    perfilesEncargada:[],
    loading:false,
    mostrarEventos:false,

    // herramientas visuales
    estatusFiltro:6,

    // Cuadros de dialogo
    dialogTareasProgramadas: false,
    dialogFinalizacion:      false,
    dialogCierre:            false,
    dialogInfoUsuario:       false,
    dialogSeguimiento:       false,  // dialogo para iniciar seguimiento 
    dialogSuccess:           false,
    dialogProspecto:         false,
    dialogError:             false,
    dialogDelete:            false,
    dialoguNevaTarea:        false,
    dialogRezagados:         false,
    dialogCerrados:          false,  //   Angel Rodriguez   --  dialogCerrados
    dialogFinalizarFamiliar: false,
    dialogFiltroFecha      : false,
    dialogBuenos:            false,
    dialogMalos:             false,
		// Prospectos
    prospectos:     [], //arreglo
    prospectosAgendadaInduccion:[],
    prospectosSeguimiento:[],
    prospectosConSeguimiento:[],
    prospecto:      null,
    intentoLlamada: 0,
    contactExport:  [],
    medio_informacion: 0,
    prospectoNoDioInfo:0,
		opCierre: 0,
    eventosTimeline:[],
		sucursales:[],
    sucursal:null,
    anuncios:[],
    anuncio:null,
    cursos:[],
    curso:null,
    ciclos:[],
    ciclos_plantel:[],
    ciclo:null,
    vendedoras:[],
    vendedoras_plantel:[],
    vendedora:null,
    tareas:[],
    tarea:null,
    campanias:[],
    campania:null,
    motivos:[],
    motivo:null,
    descrip_motivo:'',
		// Alertas 
    cargar: false,
    error:        '',
    successMensaje:'',
    errorBack:false,
    textoCopiado:'COPIAR',
    search:'',	
		// Estatus de la llamada
    estatusLlamada: false,
		// Programar tarea
    opcionAddTarea: false,
		dia:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    hora:null,
    horaPrecargada:[
      {id: 1, hora:'1 hora'},
      {id: 2, hora:'2 horas'},
      {id: 3, hora:'4 horas'},
      {id: 4, hora:'24 horas'},
      {id: 5, hora:'Otra'},
    ],
    horaCargada:null,
    nota:null,
    tipo_tarea:{id: 1, tipo:'Agendada'},

    tareas_programadas:[],

    tipoDelete: null,
    notaAnterior:'',
    idtareas_prospectos: null,
    eventos:[],
    fechaini:null,
    fechafin:null,
    banderaTarea: 0, // sirve para saber si se esta actualizando la tarea, o agregando una nueva

    // Preguntas
    paraquien_el_curso:null, // Para quien es el curso
    estudioIngles:null, // Estudió inglés
    porqueEstudiarIngles:'Trabajo', // Para que quieres estudiar inglés
    dispoHorario:null, // disponibilidad de horario
    edad:null, //  edad


    dialogNotaLlamada: false,
    notaLlamada:'',
    notasLlamada:[],
    llamadasList:[],
    idestatus_llamada: null,
    
    primerContacto:false,
    nuevaSucursal: null, 

    nombreVendedora:'',

    banderaFinalizo:false,
    matricula:'',
    grupo:null,
    grupos:[],

    banderaRechazo: false,
    notaRechazo:'',
    dialogWhatsapp:false,
    mensajeWhatsapp:'',

    cantProspectosPrimeraEtapa:0,

    prospectoFinalizo: false,
    tareaLlamada:      false,
    
    showTooltipTelefono:   false,
    pasosInfoUsuario: 1,
    puntosLlamada: [],

    modalidades:[],
    modalidad:null,
    frecuencias:[],
    frecuencia: null,
    modalidadesEscuela:[],
    frecuenciasEscuela:[],

    vendedoraFiltro:null,

    tipoInfoCurso: 0,
    
    notas:'',

    datosLlamada: null,
    newComentario:'',
    comentarios:[],

    prospectosBasura:[],
    countWhatsApp:0,

    prospectosCerrados:[],                                 //Angel Rodriguez - prospectosCerrados 
   
    disableBtnTarea: false,
    nombre_completo_empleado:'',
    contra:'',

    showFamiliares: false,

    tareas_realizadas:[],
    tabs:[
      {name:'Sin seguimiento',icon:'mdi-account'},
      {name:'Con seguimiento',icon:'mdi-account'},
      {name:'Tarea agendada, inducción',icon:'mdi-account'}
    ],

    // Alertas
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,

    clasificacion: null,
    justificacion:'',

    paramBuenos:{
      estatus: false,
    },
    paramMalos:{
      estatus: false
    },
    paramCerrados:{
      estatus: false
    },
    paramResagados:{
      estatus: false
    },

    dialogCalidadProspectos:{
      estatus: false
    },

    estatusFiltro:6,

    fechas: {
      fechaini: null,
      fechafin: null,
      estatus: false
    },

    tab:null,

    comentarios:[],
		tareas_programadas:[],
		eventos:[],
		notas:'',
		idetapa:0,

		url_pdf: '',
		getEstatusLlamadaRecluta: false,
  }),

  computed: {

    ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getNuevosProspectos']),


    formTitle() {
      return "Agregar Formulario";
    },

    formTitle2() {
      return "Editar Formulario";
    },

    filterFormulario( ){

    	if( this.getdatosUsuario.idpuesto == 18 ){
    		console.log(1)

	      let data = this.formulario.filter( el => { return [19].includes( el.puesto_interes ) })

	      if( this.estatus < 4 ){
	        data = data.filter( el => { return el.aceptado == this.estatus })
	      }

    	}else{
    		console.log(2)
    		let data = this.formulario.filter( el => { return this.perfilesEncargada.includes( el.puesto_interes ) })

	      if( this.estatus < 4 ){
	        data = data.filter( el => { return el.aceptado == this.estatus })
	      }
    	}


      return data
    },

    filterProspectosSeguimiento(){
      if(this.prospectosConSeguimiento){
        let data = this.getdatosUsuario.idpuesto == 18 ?  this.prospectosConSeguimiento.filter( el => { return  [19].includes( el.puesto_interes ) })  : this.prospectosConSeguimiento.filter( el => { return this.perfilesEncargada.includes( el.puesto_interes ) }) 
      	
        return data.filter((prospecto)=>{
          let est = prospecto.idetapa;

          // Sin fecha seleccionada
          if(!this.fechas.fechaini && !this.fechas.fechafin){
            if(this.estatusFiltro == 6){
              return true
            }else{
              if(this.estatusFiltro == 3){
                return est == 3 || est == 4 || est == 5
              }else{
                if(est == this.estatusFiltro){return true}
              }
            }
          }

          // Solo fecha de inicio seleccionada
          if(this.fechas.fechaini && !this.fechas.fechafin){
            if( prospecto.fecha == this.fechas.fechaini){
              if(this.estatusFiltro == 6){
                return true
              }else{
                if(this.estatusFiltro == 3){
                  return est == 3 || est == 4 || est == 5
                }else{
                  if(est == this.estatusFiltro){return true}
                }
              }
            }
          }

          // Solo fecha de inicio seleccionada
          if(this.fechas.fechaini && this.fechas.fechafin){
            if(prospecto.fecha >= this.fechas.fechaini && prospecto.fecha <= this.fechas.fechafin ){
              if(this.estatusFiltro == 6){
                return true
              }else{
                if(this.estatusFiltro == 3){
                  return est == 3 || est == 4 || est == 5
                }else{
                  if(est == this.estatusFiltro){return true}
                }
              }
            }
          }
        })
      }
    },

    filterProspectosTareas(){
      if(this.prospectosAgendadaInduccion){

        let data = this.getdatosUsuario.idpuesto == 18 ?  this.prospectosAgendadaInduccion.filter( el => { return  [19].includes( el.puesto_interes ) })  : this.prospectosAgendadaInduccion.filter( el => { return this.perfilesEncargada.includes( el.puesto_interes ) }) 

        return data.filter((prospecto)=>{
          let est = prospecto.idetapa;

          // Sin fecha seleccionada
          if(!this.fechas.fechaini && !this.fechas.fechafin){
            if(this.estatusFiltro == 6){
              return true
            }else{
              if(this.estatusFiltro == 3){
                return est == 3 || est == 4 || est == 5
              }else{
                if(est == this.estatusFiltro){return true}
              }
            }
          }

          // Solo fecha de inicio seleccionada
          if(this.fechas.fechaini && !this.fechas.fechafin){
            if( prospecto.fecha == this.fechas.fechaini){
              if(this.estatusFiltro == 6){
                return true
              }else{
                if(this.estatusFiltro == 3){
                  return est == 3 || est == 4 || est == 5
                }else{
                  if(est == this.estatusFiltro){return true}
                }
              }
            }
          }

          // Solo fecha de inicio seleccionada
          if(this.fechas.fechaini && this.fechas.fechafin){
            if(prospecto.fecha >= this.fechas.fechaini && prospecto.fecha <= this.fechas.fechafin ){
              if(this.estatusFiltro == 6){
                return true
              }else{
                if(this.estatusFiltro == 3){
                  return est == 3 || est == 4 || est == 5
                }else{
                  if(est == this.estatusFiltro){return true}
                }
              }
            }
          }
        })
      }
    },

    filterProspectos(){
      if(this.prospectosSeguimiento){

      	let data = this.getdatosUsuario.idpuesto == 18 ?  this.prospectosSeguimiento.filter( el => { return  [19].includes( el.puesto_interes ) })  : this.prospectosSeguimiento.filter( el => { return this.perfilesEncargada.includes( el.puesto_interes ) }) 


        return data.filter((prospecto)=>{
          let est = prospecto.idetapa;

          // Sin fecha seleccionada
          if(!this.fechas.fechaini && !this.fechas.fechafin){
            if(this.estatusFiltro == 6){
              return true
            }else{
              if(this.estatusFiltro == 3){
                return est == 3 || est == 4 || est == 5
              }else{
                if(est == this.estatusFiltro){return true}
              }
            }
          }

          // Solo fecha de inicio seleccionada
          if(this.fechas.fechaini && !this.fechas.fechafin){
            if( prospecto.fecha == this.fechas.fechaini){
              if(this.estatusFiltro == 6){
                return true
              }else{
                if(this.estatusFiltro == 3){
                  return est == 3 || est == 4 || est == 5
                }else{
                  if(est == this.estatusFiltro){return true}
                }
              }
            }
          }

          // Solo fecha de inicio seleccionada
          if(this.fechas.fechaini && this.fechas.fechafin){
            if(prospecto.fecha >= this.fechas.fechaini && prospecto.fecha <= this.fechas.fechafin ){
              if(this.estatusFiltro == 6){
                return true
              }else{
                if(this.estatusFiltro == 3){
                  return est == 3 || est == 4 || est == 5
                }else{
                  if(est == this.estatusFiltro){return true}
                }
              }
            }
          }
        })
      }
    },

    canAtrasados(){
      return this.filterProspectos.filter((el)=>{ return el.idetapa == 1 && this.perfilesEncargada.includes( el.puesto_interes )}).length
    },

    cantNuevos(){
      return this.filterProspectos.filter((el)=>{ return el.idetapa == 2 && this.perfilesEncargada.includes( el.puesto_interes )}).length
    },

    cantInduccion(){
      return this.filterProspectos.filter((el)=>{ return el.idetapa == 3 && this.perfilesEncargada.includes( el.puesto_interes )}).length
    },

    cantSinInfo(){
      return this.filterProspectos.filter((el)=>{ return el.idetapa == 5 && this.perfilesEncargada.includes( el.puesto_interes )}).length
    },

    cantConInfo(){
      return this.filterProspectos.filter((el)=>{ return el.idetapa == 4 && this.perfilesEncargada.includes( el.puesto_interes )}).length
    },

    cantSeguimiento(){
      return this.filterProspectos.filter((el)=>{ return el.idetapa > 2  && this.perfilesEncargada.includes( el.puesto_interes )}).length + this.filterProspectosSeguimiento.filter((el)=>{ return el.idetapa == 4 && this.perfilesEncargada.includes( el.puesto_interes )}).length
    },

    cantTodas(){
      return this.filterProspectosSeguimiento.length + this.filterProspectosTareas.length + this.filterProspectos.length
    },

    progreso () {
      return  (( this.filterProspectosSeguimiento.length / (this.filterProspectosSeguimiento.length + this.filterProspectos.length) ) * 100).toFixed(1)
    },

    buenos(){
      return this.filterProspectos.filter((el)=>{ return el.clasificacion == 1})
    },

    malos(){
      return this.filterProspectos.filter((el)=>{ return el.clasificacion == 2})
    },
  },

  watch: {
    dialog_agregar(val) {
      val || this.close();
    },
    dialog_editar(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "prospecto.etapa"(){
      this.saveEtapa();
    }
  },

  async created() {
    console.log( this.getdatosUsuario )
    await this.puestosEncargada()
    await this.initialize()
    await this.getPlanteles()
    await this.getPerfiles()
    await this.getTrabajadores()
    await this.getPuestos()
    await this.getMedios()
    await this.getMotivos()
		await this.getTareas()

    this.url_servidor = axios.defaults.baseURL + "archivos/";
    this.url_pdf      = axios.defaults.baseURL + "proceso_recluta/";
  },

  methods: {
  	...mapActions('login', ['cambiarDrawer','cambiarEstatusLlamada','addTareas','traerProspectosNuevos']),

    puestosEncargada() {
      this.cargar = true;
      this.perfilesEncargada = [];
      
      return this.$http.get("encargadas.get/" + this.getdatosUsuario.iderp ).then((response) => {

        this.perfilesEncargada = response.data.encargada.map((registro) => { return registro.idpuesto })

        this.cargar = false;
      }).catch((error) => {
        this.validarError(error);
      })
      .finally(() => {
        this.cargar = false;
      });
    },

    initialize() {
      this.cargar = true;
      this.formulario = [];
      
      return this.$http.get("prospectos.reclutadora").then((response) => {

      	if( this.getdatosUsuario.idpuesto == 18 && ![7, 1196].includes(this.getdatosUsuario.iderp  )){
	        // Prospectos que se encuentran el inducción o tienen tarea programada que no es para hoy
	        this.prospectosAgendadaInduccion  = this.getEscuela == 2 ? response.data.prospectosAgendadaInduccion.filter( el => el.plantel.match('FAST') )  : response.data.prospectosAgendadaInduccion.filter( el => !el.plantel.match('SIN PLANTEL') && !el.plantel.match('FAST'))

	        // Prospectos sin seguimiento hoy y sin tareas programadas
	        this.prospectosSeguimiento        = this.getEscuela == 2 ? response.data.prospectosSeguimiento.filter( el => el.plantel.match('FAST') )  : response.data.prospectosSeguimiento.filter( el => !el.plantel.match('SIN PLANTEL') && !el.plantel.match('FAST'))

	        // Prospectos con seguimiento hoy y sin tareas programadas
	        this.prospectosConSeguimiento     = this.getEscuela == 2 ? response.data.prospectosConSeguimiento.filter( el => el.plantel.match('FAST') )  : response.data.prospectosConSeguimiento.filter( el => !el.plantel.match('SIN PLANTEL') && !el.plantel.match('FAST'))

	        // Prospectos basura
	        this.prospectosBasura             = this.getEscuela == 2 ? response.data.prospectosBasura.filter( el => el.plantel.match('FAST') )  : response.data.prospectosBasura.filter( el => !el.plantel.match('SIN PLANTEL') && !el.plantel.match('FAST'))

	        this.prospectosCerrados           = []

      	}else{
      		// Prospectos que se encuentran el inducción o tienen tarea programada que no es para hoy 
	        this.prospectosAgendadaInduccion  = response.data.prospectosAgendadaInduccion

	        // Prospectos sin seguimiento hoy y sin tareas programadas
	        this.prospectosSeguimiento        = response.data.prospectosSeguimiento

	        // Prospectos con seguimiento hoy y sin tareas programadas
	        this.prospectosConSeguimiento     = response.data.prospectosConSeguimiento

	        // Prospectos basura
	        this.prospectosBasura             = response.data.prospectosBasura

	        this.prospectosCerrados           = response.data.prospectosCerrados               //Angel Rodriguez
      	}

      	if( this.getdatosUsuario.iderp == 1258 ){
      		this.prospectosAgendadaInduccion = this.prospectosAgendadaInduccion.filter( el => el.idplantel == 25 )
					this.prospectosSeguimiento = this.prospectosSeguimiento.filter( el => el.idplantel == 25 )
					this.prospectosConSeguimiento = this.prospectosConSeguimiento.filter( el => el.idplantel == 25 ) 
					this.prospectosBasura = this.prospectosBasura.filter( el => el.idplantel == 25 )
      	}


        this.cargar = false;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => {
        this.cargar = false;
      });
    },

    async save() {
     
      //Validaciones de campos

      if (this.defaultItem.nombre_completo == "") {
        return this.validarErrorDirecto("Favor de ingresar el nombre completo");
      }

      if (this.defaultItem.edad == "") {
        return this.validarErrorDirecto("Favor de ingresar la edad");
      }

      if (this.defaultItem.correo == "") {
        return this.validarErrorDirecto("Favor de ingresar el correo");
      }

      if (this.defaultItem.direccion == "") {
        return this.validarErrorDirecto("Favor de ingresar la dirección");
      }

      if (this.defaultItem.numero_wa == "") {
        return this.validarErrorDirecto("Favor de ingresar el telefono");
      }

      let puesto = this.puestos.find(
        (el) => el.idpuesto == this.defaultItem.puesto_interes
      );

      if (!puesto) {
        return this.validarErrorDirecto("Favor de seleccionar un puesto");
      }

      // let fotosPOR = [];
      // if (this.vistaPreviasPOR.length) {
      //   let respuesta = await this.subirFotosServidorPOR();
      //   fotosPOR = respuesta.data;
      // }
      // let nombrePOR = fotosPOR.nombre;

      let fotosCV = [];
      if (this.vistaPreviasCV.length) {
        let respuesta = await this.subirFotosServidorCV();
        fotosCV = respuesta.data;
      }
      let nombreCV = fotosCV.nombre;

      let payload = {
        nombre_completo: this.defaultItem.nombre_completo,
        edad: this.defaultItem.edad,
        correo: this.defaultItem.correo,
        direccion: this.defaultItem.direccion,
        numero_wa: this.defaultItem.numero_wa,
        puesto_interes: this.defaultItem.puesto_interes,
        alguien_depende: this.defaultItem.alguien_depende,
        estudiando: this.defaultItem.estudiando,
        horario_estudio: this.defaultItem.horario_estudio,
        trabajando: this.defaultItem.trabajando,
        horario_trabajo: this.defaultItem.horario_trabajo,
        medio_vacante: this.defaultItem.medio_vacante,
        dominio_ingles: this.defaultItem.dominio_ingles,
        cv: nombreCV,
        portafolio: this.defaultItem.portafolio,
      };

      this.$http
        .post("formulario.add", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.initialize();
          this.$nextTick(() => {
            this.defaultItem = Object.assign({}, this.cleanItem);
            this.editedIndex = -1;
          });
          (this.vistaPreviasCV = []), (this.vistaPreviasPOR = []);
        })
        .catch((error) => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
      this.close();
    },

    async update() {
      //Conseguimos la info para las fotos
      let fotosCV = [];
      // let fotosPOR = [];

      if (this.vistaPreviasCV.length) {
        let respuesta = await this.subirFotosServidorCV();
        fotosCV = respuesta.data;
      }

      // if (this.vistaPreviasPOR.length) {
      //   let respuesta = await this.subirFotosServidorPOR();
      //   fotosPOR = respuesta.data;
      // }

      //aqui esta la info del CV y el Portafolio
      let nombreCV = fotosCV.nombre;
      // let nombrePOR = fotosPOR.nombre;

      let payload = {
        id: this.editedItem.idformulario,
        nombre_completo: this.editedItem.nombre_completo,
        edad: this.editedItem.edad,
        correo: this.editedItem.correo,
        direccion: this.editedItem.direccion,
        numero_wa: this.editedItem.numero_wa,
        puesto_interes: this.editedItem.puesto_interes,
        alguien_depende: this.editedItem.alguien_depende,
        estudiando: this.editedItem.estudiando,
        horario_estudio: this.editedItem.horario_estudio,
        trabajando: this.editedItem.trabajando,
        horario_trabajo: this.editedItem.horario_trabajo,
        medio_vacante: this.editedItem.medio_vacante,
        dominio_ingles: this.editedItem.dominio_ingles,
        cv: this.editedItem.cv, //La informacion de cv y portafolio esta oculta
        portafolio: this.editedItem.portafolio,
        motivo_rechazo: null,
        aceptado: 0,
        deleted: 0,
      };

      //En caso de que se quiera actualizar el CV o el portafolio actualizamo el payload antes de enviarlo
      if (nombreCV) {
        payload.cv = nombreCV;
      }

      // if (nombrePOR) {
      //   payload.portafolio = nombrePOR;
      // }

      if (this.dialogConfirmacion == true) {
        payload.aceptado = 1;
      }

      if (this.dialogRechazar == true) {
        payload.aceptado = 2;
        payload.motivo_rechazo = this.mensajeRechazo;
      }

      this.cargar = true;
      this.$http
        .put("formulario.update/" + payload.id, payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.initialize();
          this.addcvfiles = [];
          this.addporfiles = [];
          this.vistaPreviasCV = "";
          this.vistaPreviasPOR = "";
          this.dialogConfirmacion = false;
          this.dialogRechazar = false;
          this.mensajeRechazo = "";
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });

      this.close();
    },

    getPlanteles() {
      this.cargar = true;
      this.planteles = [];
      return this.$http.get("planteles.activos")
        .then((response) => {
          this.planteles = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getPerfiles() {
      this.cargar = true;
      this.perfiles = [];
      return this.$http
        .get("usuarios.erp.get.perfilesERP")
        .then((response) => {
          this.perfiles = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getTrabajadores() {
      this.cargar = true;
      this.trabajadores = [];
      return this.$http
        .get("usuarios.erp.get.trabajadoresERP")
        .then((response) => {
          this.trabajadores = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getUsuarios() {
      this.cargar = true;
      this.usua = [];
      return this.$http
        .get("usuarios.erp.get.IdUsuarioERP")
        .then((response) => {
          this.usua = response.data;
          this.valor = this.usua[0].id_agregar;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.horarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_editar = true;
    },

    infoConfirmacion(item) {
      this.editedIndex = this.horarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      this.editedIndex = this.horarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog_editar = false;
      this.dialog_agregar = false;
      this.$nextTick(() => {
        this.defaultItem = Object.assign({}, this.cleanItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialog() {
      this.dialog = false;
    },

    //Angel
    cargarFotosCV() {
      if (this.addcvfiles.length < 1) {
        return;
      }

      this.addcvfiles.forEach((element, index) => {
        // creamos una variable tipo FormData
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", element);
        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
        this.getBase64CV(element, formData);
      });
    },

    cargarFotosPOR() {
      if (this.addporfiles.length < 1) {
        return;
      }

      this.addporfiles.forEach((element, index) => {
        // creamos una variable tipo FormData
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", element);
        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
        this.getBase64POR(element, formData);
      });
    },

    getBase64CV(file, formData) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const nombreSplit = file.name.split(".");
        const extensioArchivo = nombreSplit[nombreSplit.length - 1];
        me.vistaPreviasCV.push({
          url: reader.result,
          formData: formData,
          image_name: file.name,
          file,
          extensioArchivo,
        });
      };
    },

    getBase64POR(file, formData) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const nombreSplit = file.name.split(".");
        const extensioArchivo = nombreSplit[nombreSplit.length - 1];
        me.vistaPreviasPOR.push({
          url: reader.result,
          formData: formData,
          image_name: file.name,
          file,
          extensioArchivo,
        });
      };
    },

    eliminarFoto(value) {
      this.vistaPreviasCV.forEach((element, index) => {
        if (element.url == value) {
          this.vistaPreviasCV.splice(index, 1);
        }
      });
      this.vistaPreviasPOR.forEach((element, index) => {
        if (element.url == value) {
          this.vistaPreviasPOR.splice(index, 1);
        }
      });
    },

    eliminarfile(index, value) {
      this.addcvfiles = this.addcvfiles.filter((el) => {
        return el.name != value;
      });
      this.addporfiles = this.addporfiles.filter((el) => {
        return el.name != value;
      });
      this.addcvfiles2 = this.addcvfiles2.filter((el) => {
        return el.name != value;
      });
      this.addporfiles2 = this.addporfiles2.filter((el) => {
        return el.name != value;
      });
    },

    //Antes de subir las fotos las pasamos por aqui
    subirFotosServidorCV() {
      let formData = new FormData();

      for (const i in this.vistaPreviasCV) {
        formData.append("file", this.vistaPreviasCV[i].file);
      }

      return new Promise((resolve, reject) => {
        // obtener la extensión del archivo
        // Hacemos la petición
        this.$http
          .post(`formulario.add_archivo`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      });
    },

    subirFotosServidorPOR() {
      let formData = new FormData();

      for (const i in this.vistaPreviasPOR) {
        formData.append("file", this.vistaPreviasPOR[i].file);
      }

      return new Promise((resolve, reject) => {
        // obtener la extensión del archivo
        // Hacemos la petición
        this.$http
          .post(`formulario.add_archivo`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      });
    },

    getPuestos() {
      this.puestos = [];
      return this.$http
        .get("formulario.get_all_puestos")
        .then((response) => {
          this.puestos = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getMedios() {
      this.medios = [];
      return this.$http
        .get("formulario.get_all_medio_vacante")
        .then((response) => {
          this.medios = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    // Datos para grabar el prospecto
    // Abrir modal que ayuda a visualizar al prospecto
    async verProspecto(prospecto, idetapa) {

      this.cargar               = true
      // Limpiamos los datos
      this.prospecto            = prospecto
      this.comentarios          = []
      this.tareas_programadas   = []
      this.eventos              = []
      this.notas                = ''
      this.idetapa              = prospecto.idetapa

      // Mandamos a llamar la información del usuario
      this.$http.get('formulario.datos/' + this.prospecto.idformulario).then((response) => {
        // Vemos la etapa en la que esta en ese momento
        // Agregamos los puntos inciales
        this.comentarios        = response.data.comentarios
        this.tareas_realizadas  = response.data.tareas_realizadas
        this.eventos            = response.data.eventos
        this.llamadasList       = response.data.llamadas
        this.tareas_programadas = response.data.tareas
        this.eventosTimeline    = response.data.timeLine

        // Contador de whatsApp's
        this.countWhatsApp = 0
        const contadorWhats = this.eventos.filter(el=> { return el.idtipo_evento == 5})
        this.countWhatsApp = contadorWhats.length

        // Abrimos la modal para poder visualizar la información del prospecto
        this.dialogProspecto = true

        this.cargar = false
        this.loading = false

        if(this.getEstatusLlamadaRecluta){
          // validar si no tiene datos grabados, si no hay, hay que cerrar el dialogo de seguimiento y abrir el de info usuario
          if(!this.datosLlamada){
            if(!this.dialogInfoUsuario){
              setTimeout(() => { 
                // Y abrimos la información del usuario y cerrramos el seguimiento
                this.dialogSeguimiento = false
                this.dialogInfoUsuario = true
              }, 1000);
            }
          }
        }

      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    /*
        **************** FUNCIONES PARA LAS TAREAS ********************
      */

      abrirTareaIndividual(item){
        // mandaré un 1, para saber que viene de esta función y así iniciar el seguimiento
        this.verProspecto(item, item.idetapa, 1)
      },

      // Programar una nueva tarea
      programarTarea () {
        this.disableBtnTarea = true

        let payload = {
          idtareas:     this.tarea.idtareas,
          tarea:        this.tarea.tarea,
          idmotivos:    this.motivo.idmotivos,
          dia:          this.dia,
          hora:         this.hora,
          idformularios:this.prospecto.idformulario,
          idusuarioerp: this.getdatosUsuario.iderp,
          estatus:      0,
          medio:        this.tarea.tipo_tarea == 1 ? this.prospecto.numero_wa : this.tarea.tipo_tarea == 2 ? this.prospecto.numero_wa : this.prospecto.email,
          tipo_tarea:   this.tipo_tarea.id,
          descrip_motivo:this.descrip_motivo
        }

        const fehaHoy = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

        if(moment(fehaHoy).isAfter(moment(this.dia))){
          this.errorBack = false
          this.error     = 'No puedes agendar llamadas antes de hoy'
          this.dialogError = true
          return
        }

        if(!this.horaCargada){
          this.horaCargada = { id: 5 }
        }
        // Revisemo la horaCargada
        switch(this.horaCargada.id){
          case 1:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 1,':',MINUTE(now()),':00'))` 
            payload.dia  = `'${this.dia}'`
          break;
          case 2:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 2,':',MINUTE(now()),':00'))` 
            payload.dia  = `'${this.dia}'`
          break;
          case 3:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 4,':',MINUTE(now()),':00'))` 
            payload.dia  = `'${this.dia}'`
          break;
          case 4:
            payload.hora = `(SELECT TIME(NOW()))` 
            payload.dia  = `(SELECT DATE_ADD(DATE(NOW()), INTERVAL 1 DAY))` 
          break;
          case 5:
            payload.hora = `'${this.hora}'`
            payload.dia  = `'${this.dia}'`
          break;
        }


        this.$http.post('reclutadora.tarea.add', payload).then( async (response) => {
          this.opcionAddTarea     = false
          this.motivo             = null
          this.horaCargada        = null
          this.dialogCierre       = false
          this.dialoguNevaTarea   = false
          if(this.idetapa == 1 || this.idetapa == 2){ await this.validEtapa( 5, 'Contacto sin información' ) } // sin información
          await this.getTareasProgramadas()
          await this.verProspecto(this.prospecto, this.idetapa)
          this.disableBtnTarea = false
        }).catch( error =>{
          this.dialogError = true
          this.disableBtnTarea = false
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTareasProgramadas ( ) {
        this.addTareas([])
        this.$http.get('reclutadora.tarea.usuario/' + this.getdatosUsuario.iderp).then((response) => {
          // Vamos a agregar el campo de tarea programada
          for(const i in response.data){
            response.data[i].notificada = false
          }
          this.addTareas(response.data)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      }, 

      // actualizar una tarea
      actualizarTarea ( tarea ){
        this.banderaTarea        = 1
        this.idtareas_prospectos = tarea.idtareas_prospectos 
        this.motivo              = {idmotivos: tarea.idmotivos, motivo: tarea.motivo}
        this.notaAnterior        = {idmotivos: tarea.idmotivos, motivo: tarea.motivo}
        this.dia                 = tarea.dia
        this.hora                = tarea.hora
        this.tarea               = this.tareas.find(el => el.idtareas == tarea.idtareas)
        this.horaCargada         = {id: 5, hora: 'Otra'}
        this.tipo_tarea          = tarea.tarea_tipo == 1 ? {id: 1, tipo:'Agendada'}:{id: 2, tipo:'Nota'} 
        this.descrip_motivo      = tarea.descrip_motivo
        this.opcionAddTarea      = true
        this.disableBtnTarea     = false
      },

      // Reprogramar la tarea
      reprogramarTarea () {
        this.disableBtnTarea = true
        let payload = {
          idtareas_prospectos: this.idtareas_prospectos,
          idtareas:     this.tarea.idtareas,
          tarea:        this.tarea.tarea,
          idmotivos:    this.motivo.idmotivos,
          dia:          this.dia,
          hora:         this.hora,
          idprospectos: this.prospecto.idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp,
          notaAnterior: this.notaAnterior,
          estatus:      0,
          medio:        this.tarea.tipo_tarea == 1 ? this.prospecto.telefono : this.tarea.tipo_tarea == 2 ? this.prospecto.telefono : this.prospecto.email,
          tipo_tarea:   this.tipo_tarea.id,
          descrip_motivo:this.descrip_motivo
        }

        const fehaHoy = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

        if(moment(fehaHoy).isAfter(moment(this.dia))){
          this.errorBack = false
          this.error     = ' No puedes agendar llamadas antes de hoy'
          this.dialogError = true
          return
        }

        // Revisemo la horaCargada
        switch(this.horaCargada.id){
          case 1:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 1,':',MINUTE(now()),':00'))`
            payload.dia  = `'${this.dia}'`
          break;
          case 2:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 2,':',MINUTE(now()),':00'))`
            payload.dia  = `'${this.dia}'`
          break;
          case 3:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 4,':',MINUTE(now()),':00'))`
            payload.dia  = `'${this.dia}'`
          break;
          case 4:
            payload.hora = `(SELECT TIME(NOW()))` 
            payload.dia  = `(SELECT DATE_ADD(DATE(NOW()), INTERVAL 1 DAY))` 
          break;
          case 5:
            payload.hora = `'${this.hora}'`
            payload.dia  = `'${this.dia}'`
          break;
          default:
            payload.hora   = `'${this.tarea.hora}'`
            payload.dia    = `'${this.tarea.dia}'`
          break;
        }

        this.$http.put('prospectos.tarea.update', payload).then((response) => {
          this.opcionAddTarea = false
          this.nota = ''
          this.dia  = null
          this.hora = null
          this.getTareasProgramadas()
          this.verProspecto(this.prospecto, this.idetapa)
          this.disableBtnTarea = false
        }).catch( error =>{
          this.disableBtnTarea = false
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Eliminar la tarea
      eliminarTarea(id){
        this.idtareas_prospectos      = id
        this.paramDeleteTarea.estatus = true
      },

      // confirmación de que se eliminiará la tarea
      deleteItemConfirm() {
        // Mixin funciones
        this.mEliminarTarea( this.idtareas_prospectos )
      },

      // Verificar que la tarea se realizo
      tareaRealizada( id ) {
        this.$http.put(`reclutadora.tarea.check/${id}`).then((response) => {
          this.dialogDelete  = false
          this.dialogSuccess = true
          this.tareaLlamada  = false
          this.verProspecto(this.prospecto, this.idetapa)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      siguiente(){
      	console.log( this.prospecto.idformulario )
        // Validar si hay nuevos:
        const position = this.filterProspectos.map(formulario => formulario.idformulario).indexOf(this.prospecto.idformulario)

        if(position <= this.filterProspectos.length - 1){
          this.loading = true
          this.cargar = true
          this.verProspecto(this.filterProspectos[position + 1], 1,0)
        }
      },

      anterior(){
        const position = this.filterProspectos.map(formulario => formulario.idformulario).indexOf(this.prospecto.idformulario)
        if(position > 0){
          this.loading = true
          this.cargar = true
          this.verProspecto(this.filterProspectos[position - 1], 1,0)
        }
      },

    // Validar la etapa del prospecto
    validEtapa( idetapa, etapa ){
    	console.log( etapa )
      const payload = {
        idetapa,
        etapa,
        usuario_creo: this.getdatosUsuario.iderp
      }
      this.$http.put('formulario.etapa/' + this.prospecto.idformulario, payload).then((response) => {
        return
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    finalizarLlamada(){
      // Validamos si estaba en una tarea de llamada
      if(this.tareaLlamada){
        this.tipoDelete = 1
        if(this.tareas_programadas.length > 0){
          this.tareaRealizada(this.tareas_programadas[0].idtareas_reclutadora)
        }
      }

      this.getTareasProgramadas()

      // Abrimos para agendar una nueva tarea
      this.dialogCierre = true
      // Cerramos la llamada
      this.resetCronometro()
      this.abrirCerrarLlamada(false)
    },

    verPDF( value, value2 ){
    	console.log( value )
    	this.prospecto = value
    	this.dialogCV.estatus = true
    	
    },

    saveEtapa( ){

    	const existeEtapa = this.etapasProspectos.find( el => el.etapa == this.prospecto.etapa )

    	const payload = {
    		idetapa     : this.prospecto.etapa,
    		etapa       : existeEtapa ? existeEtapa.text : 'Sin etapa',
    		usuario_creo: this.getdatosUsuario.iderp
    	}

    	this.$http.put('formulario.etapa.prospecto/' + this.prospecto.idformulario, payload).then(async (response) => {
        await this.verProspecto(this.prospecto, this.idetapa)
        // Abrimo la modal para grabar una tarea
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })

    },

    finalizarProspecto (){
      const payload = {
        idformulario    : this.prospecto.idformulario,
        matricula       :    1,
        idusuarioerp    : this.getdatosUsuario.iderp,
        idgrupo         : 1,
        nombres         : this.nombres,
        apellido_paterno: this.apellido_paterno,
        apellido_materno: this.apellido_materno,

      }

      // Hacer la peticion de los datos
      this.$http.post('formularios.finalizar.ok', payload).then( async (response) => {
        this.dialogSuccess      = true
        this.dialogProspecto    = false
        this.dialogFinalizacion = false
        this.dialogTarea        = false
        this.notaRechazo        = ''
        this.banderaFinalizo    = false
        this.banderaRechazo     = false
        this.nombres            = ''
				this.apellido_paterno   = ''
				this.apellido_materno   = ''
        this.initialize()
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    rechazoProspecto(){
      if(this.notaRechazo != ''){
        const payload = {
          idformulario: this.prospecto.idformulario,
          idusuarioerp: this.getdatosUsuario.iderp,
          notaRechazo:  this.notaRechazo
        }

        // Hacer la peticion de los datos
        this.$http.post('formularios.finalizar.nok', payload).then((response) => {
          this.dialogSuccess      = true
          this.dialogProspecto    = false
          this.notaRechazo        = ''
          this.banderaFinalizo    = false
          this.banderaRechazo     = false
          this.cerrarDialogCierre()
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      }else{ 
        this.validarErrorDirecto('Por favor, ingresa los datos correctamente') 
      }
    },

    cerrarDialogCierre(){
    	this.dialogProspecto = false
      this.dialogFinalizacion = false
      this.dialogTarea        = false
      this.dialogCierre       = false
      this.opCierre           = 0
      this.banderaRechazo     = false
      this.banderaFinalizo    = false
      this.notaRechazo        = ''
      this.matricula          = null
      this.grupo              = null
    },


  },
};
</script>


