<template>
	<div>
		<!-- :items="prospectosSeguimiento" -->
    <v-data-table
      :headers="headers"
      :items="items"
      :sort-desc="[true, false,false,false,false]"
      class="elevation-0"
      :search="search"
      dense
      item-key="folio"
      show-select
    >

      <!-- Datos dinámicos de las tablas -->
      <template v-slot:item.actions="{ item }">
        <v-tooltip top >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              @click="$emit('verProspecto', item )"
            >
              <!-- v-if="item.idcontador == 0" -->
              <v-icon >
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </template>
          <span>Ver prospecto</span>
        </v-tooltip>

        <v-tooltip top >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              @click="$emit('verPDF', item )"
            >
              <v-icon>
               	mdi-file-pdf-box
              </v-icon>
            </v-btn>
          </template>
          <span>Ver</span>
        </v-tooltip>

      </template>

      <template v-slot:item.etapa="{ item }">
      	<span v-if="item.etapa == 0"> Sin contacto</span>
      	<span v-if="item.etapa == 1"> 1era entrevista</span>
		    <span v-if="item.etapa == 2"> Prueba Técnica</span>
		    <span v-if="item.etapa == 3"> Prueba psicológica</span>
		    <span v-if="item.etapa == 4"> Entrevista Técnica</span>
		    <span v-if="item.etapa == 5"> Inv. Laboral</span>
		    <span v-if="item.etapa == 6"> Exámenes Médicos</span>
		    <span v-if="item.etapa == 7"> Firma de contrato</span>
		    <span v-if="item.etapa == 8"> Inducción</span>
		    <span v-if="item.etapa == 9"> Capacitación</span>
		    <span v-if="item.etapa == 10"> Incorporación</span>
      </template>

      <template v-slot:item.idetapa="{ item }">
        <!-- chip para prospectos atrasados -->
        <v-tooltip bottom color="red" v-if="item.idetapa == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-chip small  color="red" dark  v-bind="attrs" v-on="on">Atrasado</v-chip>
          </template>
          <span>El proespecto esta esperando que se contacten con el</span>
        </v-tooltip>

        <!-- chip para prospectos nuevos -->
        <v-tooltip bottom color="green" v-if="item.idetapa == 2">
          <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 2">
            <v-chip small  color="green"  dark  v-if="item.idetapa == 2" v-bind="attrs" v-on="on">Nuevo</v-chip>
          </template>
          <span>Prospecto llego hoy y no tiene información</span>
        </v-tooltip>

        <!-- chip para prospectos en inducción -->
        <v-tooltip bottom color="pink" v-if="item.idetapa == 3">
          <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 3">
            <v-chip small  color="pink"   dark  v-if="item.idetapa == 3" v-bind="attrs" v-on="on"><v-icon v-if="item.dia_induccion == 1" color="yellow" left>mdi-bell</v-icon>Inducción</v-chip>
          </template>
          <span>El prospecto se encuentra en inducción</span>
        </v-tooltip>

        <!-- chip para prospectos en seguimiento con información -->
        <v-tooltip bottom color="orange" v-if="item.idetapa == 4">
          <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 4">
            <v-chip small  color="orange" dark  v-if="item.idetapa == 4" v-bind="attrs" v-on="on">Con Info</v-chip>
          </template>
          <span>Al prospecto ya se le brindo información</span> 
        </v-tooltip>

        <!-- chip para prospectos con información -->
        <v-tooltip bottom color="brown" v-if="item.idetapa == 5">
          <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 5">
            <v-chip small  color="brown"  dark  v-if="item.idetapa == 5" v-bind="attrs" v-on="on">Sin Info</v-chip>
          </template>
          <span>Al prospecto no se le ha podido brindar información</span>
        </v-tooltip>
      </template>

      <template v-slot:item.tareasP="{ item }">
        <div v-if="item.tareasP.length > 0">
          
          <!-- Chip, tarea agendada -->
          <v-tooltip bottom :color="item.tareasP[0].cumplida == 1 ? 'red' : 'yellow'" v-if="item.tareasP[0].tipo_tarea == 1">
            <template v-slot:activator="{ on, attrs }" >
              <v-chip 
                small  
                :color="item.tareasP[0].cumplida == 1 ? 'red' : 'yellow'"
                v-bind="attrs" 
                v-on="on"
                :dark="item.tareasP[0].cumplida == 1 ? true : false"
              >
                <v-icon>mdi-calendar-clock</v-icon>
              </v-chip>
            </template>

            <span class="white--text" v-if="item.tareasP[0].cumplida == 1 && item.tareasP[0].tipo_tarea == 1" dark>Existe una tarea agendada: {{ item.tareasP[0].fecha }} , {{ item.tareasP[0].hora }}</span>
            <span class="black--text" v-if="item.tareasP[0].cumplida == 0 && item.tareasP[0].tipo_tarea == 1">Existe una tarea agendada: {{ item.tareasP[0].fecha }} , {{ item.tareasP[0].hora }}</span>
          </v-tooltip>


          <!-- Chip, tarea como nota -->
          <v-tooltip bottom color="#004D40" v-if="item.tareasP[0].tipo_tarea == 2">
            <template v-slot:activator="{ on, attrs }" >
              <!-- Chip, tarea como nota -->
              <v-chip 
                dark
                small  
                color="#004D40"
                v-bind="attrs" 
                v-on="on"
              >
                <v-icon>mdi-calendar-blank</v-icon>
              </v-chip>
            </template>

            <span class="white--text" dark>Existe una tarea como nota: {{ item.tareasP[0].fecha }}</span>
          </v-tooltip>

        </div>
      </template>

      <template v-slot:item.tiempo_transcurrido="{ item }">

        <!-- chip para prospectos atrasados -->
        <v-tooltip bottom color="red">
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="red"  small dark class="pa-1 mr-1" v-if="item.tiempo_transcurrido >= 30 " v-bind="attrs" v-on="on"> {{ item.tiempo_transcurrido }} días</v-chip>
          </template>
          <span>El prospecto ya lleva mucho tiempo y no responde, cerrar!!</span>
        </v-tooltip>

        <v-tooltip bottom color="green">
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="green"  small dark class="pa-1 mr-1" v-if="item.tiempo_transcurrido < 30 " v-bind="attrs" v-on="on"> {{ item.tiempo_transcurrido }} días</v-chip>
          </template>
          <span>El prospecto tiene {{ item.tiempo_transcurrido }} días que ingreso</span>
        </v-tooltip>
      </template>

      <template v-slot:item.intentoLlamada="{ item }">
        <v-tooltip bottom color="red" v-if="item.intentoLlamada == 9 || item.intentoLlamada == 10">
          <template v-slot:activator="{ on, attrs }" v-if="item.intentoLlamada == 9 || item.intentoLlamada == 10">
            <v-btn fab x-small color="red" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
              <v-icon x-small> mdi-phone-hangup </v-icon>
            </v-btn>
          </template>
          <span v-if="item.intentoLlamada == 9">Se realizo un intento de llamada</span>
          <span v-if="item.intentoLlamada == 10">Se realizo un intento de llamada por whatsApp</span>
        </v-tooltip>

        <v-tooltip bottom color="blue" v-if="item.intentoLlamada == 11 || item.intentoLlamada == 12">
          <template v-slot:activator="{ on, attrs }" v-if="item.intentoLlamada == 11 || item.intentoLlamada == 12">
            <v-btn fab x-small color="blue" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
              <v-icon x-small> mdi-phone </v-icon>
            </v-btn>
          </template>
          <span v-if="item.intentoLlamada == 11">Se realizo una llamada normal</span>
          <span v-if="item.intentoLlamada == 12">Se realizo una llamada por whatsApp</span>
        </v-tooltip>

        <v-tooltip bottom color="orange" v-if="![9,10,11,12].includes(item.intentoLlamada) && item.medio_informacion == 1">
          <template v-slot:activator="{ on, attrs }" v-if="![9,10,11,12].includes(item.intentoLlamada) && item.medio_informacion == 1">
            <v-btn fab x-small color="orange" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
              <v-icon x-small> mdi-store </v-icon>
            </v-btn>
          </template>
          <span>Llegó directo a sucursal</span>
        </v-tooltip>
      </template>

    </v-data-table>
	</div>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'

	// Componentes
  export default {

  	props:['items', 'headers', 'search'],

    data: () => ({
			url_servidor:'',      
    }),

    async created () {
    	this.url_servidor = axios.defaults.baseURL + "archivos/";
    },
  };
</script>