<template>
	<!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="parametros.estatus"
    persistent
    max-width="800"
  >
		<v-card>
      <v-card-title>
        <span class="text-subtitle-1 font-weight-bold text-center">
          Visualizar PDF
        </span>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text v-if="prospecto">
        <v-card
          class="py-4 shadowCard"
        >
          <embed :src="url_servidor + prospecto.cv" type="application/pdf" width="100%" height="600px" v-if="['PDF','pdf'].includes( extension )"/>
          <v-img :src="url_servidor + prospecto.cv" width="100%" v-if="['png','jpg','JPG','jpeg','JPEG','webp'].includes( extension )"/>
          <iframe :src="`https://view.officeapps.live.com/op/view.aspx?src=${url_servidor + prospecto.cv}`" width="100%" height="800" v-if="['doc','docx'].includes( extension )"></iframe>
        </v-card>
      </v-card-text>

      <v-card-actions>
      	<v-spacer></v-spacer>
        <v-btn
          color="black"
          dark
          small
          tile
          @click="parametros.estatus = false"
        >
        	<v-icon small left>mdi-close</v-icon>
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>

	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'

	// Componentes
  export default {

  	props:['prospecto', 'parametros'],

    data: () => ({
			url_servidor:'',  
      extension:'',    
    }),

    async created () {
      const nombreArchivo = this.prospecto.cv.split('.')
      this.extension = nombreArchivo[ nombreArchivo.length - 1 ]
    	this.url_servidor = axios.defaults.baseURL + "archivos/";
    },
  };
</script>
