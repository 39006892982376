<template>
	<v-dialog
    v-model="colaboradores.estatus"
    hide-overlay
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title primary-title >
        Colaboradores
      </v-card-title>
      <v-card-text >

      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
          <v-text-field
            v-model="search"
            dense
            rounded
            filled
            single-line
            label="Buscar"
            append-icon="mdi-magnify"
          ></v-text-field>  
        </v-col>

        <v-data-table
          :headers="headerColaboradores"
          :items="colaboradoresList"
          class="elevation-0"
          dense
          :search="search"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
        	color="error" 
        	tile 
        	dark 
        	@click="cerrar()"
        >
        	<v-icon left small>mdi-cancel</v-icon>
      		Cancelar
      	</v-btn>

      </v-card-actions>
      <v-btn 
        color="transparent elevation-0"
        x-small
        absolute
        top
        right
        fab
        class="mt-5 mr-0"
        @click="cerrar()"
       ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'colaboradores',
			'prospectosCerrados'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

			colaboradoresList:[],

      headerColaboradores: [
        { text: 'Folio'      , value: 'idformulario'     },
        { text: 'Nombres'    , value: 'nombres'          },  
        { text: 'Apellido P.', value: 'apellido_paterno' },  
        { text: 'Apellido M.', value: 'apellido_materno' },  
      ],
      search:'',
    }),

    created(){
    	this.initialize()
    },


    methods:{
    	initialize(idformulario){
    		this.colaboradoresList = []
        // Hacer la peticion de los datos
        this.$http.get('colaboradores.list').then((response) => {
        	this.colaboradoresList = response.data
        }).catch(error=>{
          this.validError( error )
        }).finally(()=> { this.overlay = false })
      },

      cerrar(){
      	this.colaboradores.estatus = false
      },

      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      }
    }
	}
</script>
