<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="prospecto.nombre_completo"
          label="Nombre Completo"
          dense
          hide-details
          readonly
          placeholder="Ej. Juan Carlos Jimenez."
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          label="Edad"
          v-model="prospecto.edad"
          dense
          hide-details
          readonly
          type="number"
          placeholder="Ej. 20"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="prospecto.correo"
          label="Correo"
          dense
          hide-details
          readonly
          placeholder="Ej. juang.carlos@gmail.com"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="prospecto.numero_wa"
          label="Telefono"
          dense
          hide-details
          readonly
          placeholder="Ej. 8134576822."
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="prospecto.direccion"
          label="Direccion"
          dense
          hide-details
          readonly
          placeholder="Ej. C. José Santos Chocano 111"
        ></v-text-field>
      </v-col>


      <v-col cols="12" md="6">
        <v-autocomplete
          :items="puestos"
          v-model="prospecto.puesto_interes"
          placeholder="Ej. Desarrollador."
          dense
          item-text="puesto"
          item-value="idpuesto"
          hide-details
          readonly
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <label style="color: #333333">¿Alguien depende de usted?</label>
        <v-radio-group v-model="prospecto.alguien_depende" row hide-details readonly>
          <v-radio label="Sí" :value="1"></v-radio>
          <v-radio label="No" :value="0"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="6">
        <label style="color: #333333">¿Actualmente estudia?</label>
        <v-radio-group v-model="prospecto.estudiando" row hide-details readonly>
          <v-radio label="Sí" :value="1"></v-radio>
          <v-radio label="No" :value="0"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          label="Horario de Estudio"
          hide-details
          readonly
          v-model="prospecto.horario_estudio"
          single-line
          dense
          placeholder="Ej. 9:00 AM"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label style="color: #333333">¿Actualmente trabaja?</label>
        <v-radio-group v-model="prospecto.trabajando" row hide-details readonly>
          <v-radio label="Sí" :value="1"></v-radio>
          <v-radio label="No" :value="0"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          label="Horario de Trabajo"
          hide-details
          readonly
          v-model="prospecto.horario_trabajo"
          single-line
          dense
          placeholder="Ej. 9:00 AM"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
          label="Medio vacante"
          hide-details
          readonly
          :items="medios"
          v-model="prospecto.medio_vacante"
          placeholder="Ej. Pagina WEB."
          dense
          item-text="medio"
          item-value="idmedio_contacto"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <label style="color: #333333">¿Domina el ingles?</label>
        <v-radio-group v-model="prospecto.dominio_ingles" row hide-details readonly>
          <v-radio label="Sí" :value="1"></v-radio>
          <v-radio label="No" :value="0"></v-radio>
        </v-radio-group>
      </v-col>


      <v-col cols="12" md="6">
        <v-text-field
          lavel="Portafolio"
          hide-details
          readonly
          v-model="prospecto.portafolio"
          single-line
          dense
          placeholder="https://gitlab.com/gitlab-com"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">

      </v-col>

      <v-row>
        <v-col
          cols="12"
          md="11"
          v-for="(img, i) in vistaPreviasCV"
          :key="i"
        >
          <v-card
            class="py-4 shadowCard"
            v-if="img.extensioArchivo != 'pdf'"
          >
            <v-img :src="img.url" contain aspect-ratio="2"></v-img>
            <v-btn
              color="error"
              small
              @click="eliminarFoto(img.url)"
              top
              right
              absolute
              fab
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card>
          <v-card
            class="py-4 shadowCard"
            v-if="img.extensioArchivo == 'pdf'"
          >
            <embed :src="img.url" type="application/pdf" />
            <v-btn
              color="error"
              small
              @click="eliminarFoto(img.url)"
              top
              right
              absolute
              fab
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>

    </v-row>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </div>
</template>


<script>
import axios from 'axios';

  // Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";


export default {
  props:[
    'prospecto'
  ],

  mixins: [validarErrores, funcionesExcel ],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

      // Alertas
      parametros: {
        dialogError: false,
        mensaje: "",
        color: "",
      },

      respuestaAlerta: false,
      loader: true,
      cargar: false,
      plantel: null,
      planteles: [],
      puestos: [],
      medios: [],
      perfiles: [],
      trabajadores: [],
      formulario: [],
      usua: [],
      addcvfiles: [],
      addporfiles: [],
      addcvfiles2: [],
      addporfiles2: [],
      vistaPreviasCV: [],
      vistaPreviasPOR: [],
      fotosCV: [],
      fotosPOR: [],
      valor: 0,
      img: null,
      url_servidor: "",
      mensajeRechazo: "",
    }),

    async created() {
      await this.getPlanteles()
      await this.getPerfiles()
      await this.getTrabajadores()
      await this.getPuestos()
      await this.getMedios()
      this.url_servidor = axios.defaults.baseURL + "archivos/";
    },

    methods:{
      getPlanteles() {
        this.cargar = true;
        this.planteles = [];
        return this.$http.get("planteles.activos")
          .then((response) => {
            this.planteles = response.data;
            this.cargar = false;
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

      getPerfiles() {
        this.cargar = true;
        this.perfiles = [];
        return this.$http
          .get("usuarios.erp.get.perfilesERP")
          .then((response) => {
            this.perfiles = response.data;
            this.cargar = false;
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

      getTrabajadores() {
        this.cargar = true;
        this.trabajadores = [];
        return this.$http
          .get("usuarios.erp.get.trabajadoresERP")
          .then((response) => {
            this.trabajadores = response.data;
            this.cargar = false;
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

      getUsuarios() {
        this.cargar = true;
        this.usua = [];
        return this.$http
          .get("usuarios.erp.get.IdUsuarioERP")
          .then((response) => {
            this.usua = response.data;
            this.valor = this.usua[0].id_agregar;
            this.cargar = false;
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

      getPuestos() {
        this.puestos = [];
        return this.$http
          .get("formulario.get_all_puestos")
          .then((response) => {
            this.puestos = response.data;
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

      getMedios() {
        this.medios = [];
        return this.$http
          .get("formulario.get_all_medio_vacante")
          .then((response) => {
            this.medios = response.data;
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

    }
  }
</script>