var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-desc":[true, false,false,false,false],"search":_vm.search,"dense":"","item-key":"folio","show-select":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.$emit('verProspecto', item )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver prospecto")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","dark":"","icon":""},on:{"click":function($event){return _vm.$emit('verPDF', item )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-pdf-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])])]}},{key:"item.etapa",fn:function(ref){
var item = ref.item;
return [(item.etapa == 0)?_c('span',[_vm._v(" Sin contacto")]):_vm._e(),(item.etapa == 1)?_c('span',[_vm._v(" 1era entrevista")]):_vm._e(),(item.etapa == 2)?_c('span',[_vm._v(" Prueba Técnica")]):_vm._e(),(item.etapa == 3)?_c('span',[_vm._v(" Prueba psicológica")]):_vm._e(),(item.etapa == 4)?_c('span',[_vm._v(" Entrevista Técnica")]):_vm._e(),(item.etapa == 5)?_c('span',[_vm._v(" Inv. Laboral")]):_vm._e(),(item.etapa == 6)?_c('span',[_vm._v(" Exámenes Médicos")]):_vm._e(),(item.etapa == 7)?_c('span',[_vm._v(" Firma de contrato")]):_vm._e(),(item.etapa == 8)?_c('span',[_vm._v(" Inducción")]):_vm._e(),(item.etapa == 9)?_c('span',[_vm._v(" Capacitación")]):_vm._e(),(item.etapa == 10)?_c('span',[_vm._v(" Incorporación")]):_vm._e()]}},{key:"item.idetapa",fn:function(ref){
var item = ref.item;
return [(item.idetapa == 1)?_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"red","dark":""}},'v-chip',attrs,false),on),[_vm._v("Atrasado")])]}}],null,true)},[_c('span',[_vm._v("El proespecto esta esperando que se contacten con el")])]):_vm._e(),(item.idetapa == 2)?_c('v-tooltip',{attrs:{"bottom":"","color":"green"},scopedSlots:_vm._u([(item.idetapa == 2)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.idetapa == 2)?_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"green","dark":""}},'v-chip',attrs,false),on),[_vm._v("Nuevo")]):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("Prospecto llego hoy y no tiene información")])]):_vm._e(),(item.idetapa == 3)?_c('v-tooltip',{attrs:{"bottom":"","color":"pink"},scopedSlots:_vm._u([(item.idetapa == 3)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.idetapa == 3)?_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"pink","dark":""}},'v-chip',attrs,false),on),[(item.dia_induccion == 1)?_c('v-icon',{attrs:{"color":"yellow","left":""}},[_vm._v("mdi-bell")]):_vm._e(),_vm._v("Inducción")],1):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("El prospecto se encuentra en inducción")])]):_vm._e(),(item.idetapa == 4)?_c('v-tooltip',{attrs:{"bottom":"","color":"orange"},scopedSlots:_vm._u([(item.idetapa == 4)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.idetapa == 4)?_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"orange","dark":""}},'v-chip',attrs,false),on),[_vm._v("Con Info")]):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("Al prospecto ya se le brindo información")])]):_vm._e(),(item.idetapa == 5)?_c('v-tooltip',{attrs:{"bottom":"","color":"brown"},scopedSlots:_vm._u([(item.idetapa == 5)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.idetapa == 5)?_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"brown","dark":""}},'v-chip',attrs,false),on),[_vm._v("Sin Info")]):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("Al prospecto no se le ha podido brindar información")])]):_vm._e()]}},{key:"item.tareasP",fn:function(ref){
var item = ref.item;
return [(item.tareasP.length > 0)?_c('div',[(item.tareasP[0].tipo_tarea == 1)?_c('v-tooltip',{attrs:{"bottom":"","color":item.tareasP[0].cumplida == 1 ? 'red' : 'yellow'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":item.tareasP[0].cumplida == 1 ? 'red' : 'yellow',"dark":item.tareasP[0].cumplida == 1 ? true : false}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-clock")])],1)]}}],null,true)},[(item.tareasP[0].cumplida == 1 && item.tareasP[0].tipo_tarea == 1)?_c('span',{staticClass:"white--text",attrs:{"dark":""}},[_vm._v("Existe una tarea agendada: "+_vm._s(item.tareasP[0].fecha)+" , "+_vm._s(item.tareasP[0].hora))]):_vm._e(),(item.tareasP[0].cumplida == 0 && item.tareasP[0].tipo_tarea == 1)?_c('span',{staticClass:"black--text"},[_vm._v("Existe una tarea agendada: "+_vm._s(item.tareasP[0].fecha)+" , "+_vm._s(item.tareasP[0].hora))]):_vm._e()]):_vm._e(),(item.tareasP[0].tipo_tarea == 2)?_c('v-tooltip',{attrs:{"bottom":"","color":"#004D40"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"dark":"","small":"","color":"#004D40"}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-blank")])],1)]}}],null,true)},[_c('span',{staticClass:"white--text",attrs:{"dark":""}},[_vm._v("Existe una tarea como nota: "+_vm._s(item.tareasP[0].fecha))])]):_vm._e()],1):_vm._e()]}},{key:"item.tiempo_transcurrido",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.tiempo_transcurrido >= 30 )?_c('v-chip',_vm._g(_vm._b({staticClass:"pa-1 mr-1",attrs:{"color":"red","small":"","dark":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.tiempo_transcurrido)+" días")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("El prospecto ya lleva mucho tiempo y no responde, cerrar!!")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.tiempo_transcurrido < 30 )?_c('v-chip',_vm._g(_vm._b({staticClass:"pa-1 mr-1",attrs:{"color":"green","small":"","dark":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.tiempo_transcurrido)+" días")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("El prospecto tiene "+_vm._s(item.tiempo_transcurrido)+" días que ingreso")])])]}},{key:"item.intentoLlamada",fn:function(ref){
var item = ref.item;
return [(item.intentoLlamada == 9 || item.intentoLlamada == 10)?_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([(item.intentoLlamada == 9 || item.intentoLlamada == 10)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1 mr-1",attrs:{"fab":"","x-small":"","color":"red","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-phone-hangup ")])],1)]}}:null],null,true)},[(item.intentoLlamada == 9)?_c('span',[_vm._v("Se realizo un intento de llamada")]):_vm._e(),(item.intentoLlamada == 10)?_c('span',[_vm._v("Se realizo un intento de llamada por whatsApp")]):_vm._e()]):_vm._e(),(item.intentoLlamada == 11 || item.intentoLlamada == 12)?_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([(item.intentoLlamada == 11 || item.intentoLlamada == 12)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1 mr-1",attrs:{"fab":"","x-small":"","color":"blue","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-phone ")])],1)]}}:null],null,true)},[(item.intentoLlamada == 11)?_c('span',[_vm._v("Se realizo una llamada normal")]):_vm._e(),(item.intentoLlamada == 12)?_c('span',[_vm._v("Se realizo una llamada por whatsApp")]):_vm._e()]):_vm._e(),(![9,10,11,12].includes(item.intentoLlamada) && item.medio_informacion == 1)?_c('v-tooltip',{attrs:{"bottom":"","color":"orange"},scopedSlots:_vm._u([(![9,10,11,12].includes(item.intentoLlamada) && item.medio_informacion == 1)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1 mr-1",attrs:{"fab":"","x-small":"","color":"orange","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-store ")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Llegó directo a sucursal")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }