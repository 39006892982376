import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
  },

	methods: {
		// Obtener todas las sucursales
    getSucursales() {
      this.overlay = true
      this.sucursales  = []
      return this.$http.get('planteles.all').then((response) => {
        // Llenamos la etapa incial
        this.sucursales = response.data
        this.sucursal   = response.data[0]
        this.overlay = false
      }).catch(error=>{
        this.validError( error )
      }).finally(()=> { this.overlay = false })
    },

    getMotivos(){
      this.overlay = true
      this.motivos  = []
      return this.$http.get('motivos.activos.formulario').then((response) => {
        // Llenamos la etapa incial
        this.motivos = response.data
        this.overlay = false
      }).catch(error=>{
        this.validError( error )
      }).finally(()=> { this.overlay = false })
    },

    // Obtener todos los anuncios
    getAnuncios() {
      this.overlay = true
      this.anuncios  = []
      return this.$http.get('anuncios.activos').then((response) => {
        // Llenamos la etapa incial
        this.anuncios = response.data
        this.anuncio  = this.anuncios[0]
        this.overlay = false
      }).catch(error=>{
        this.validError( error )
      }).finally(()=> { this.overlay = false })
    },

    // Obtener todos los cursos
    getCursos() {
      this.overlay = true
      this.cursos  = []
      return this.$http.get('escuela.cursos.list').then((response) => {
        // Llenamos la etapa incial
        if(this.getdatosUsuario.idpuesto == 18){
          this.cursos = response.data.filter(el=> { return el.escuela == this.getEscuela})
        }else{
          this.cursos = response.data
        }
        this.overlay = false
      }).catch(error=>{
        this.validError( error )
      }).finally(()=> { this.overlay = false })
    },

    // Obtener todas las modalidades
    getModalidades() {
      this.overlay = true
      this.modalidades  = []
      return this.$http.get('escuela.modalidades.activos').then((response) => {
        // Llenamos la etapa incial
        if(this.getdatosUsuario.idpuesto == 18){
          this.modalidades = response.data.filter(el=> { return el.escuela == this.getEscuela})
        }else{
          this.modalidades = response.data
        }
        this.overlay = false
      }).catch(error=>{
        this.validError( error )
      }).finally(()=> { this.overlay = false })
    },

    // Obtener todos los cursos
    getFrecuencias() {
      this.overlay = true
      this.frecuencias  = []
      return this.$http.get('escuela.frecuencias.activos').then((response) => {
        // Llenamos la etapa incial
        if(this.getdatosUsuario.idpuesto == 18){
          this.frecuencias = response.data.filter(el=> { return el.escuela == this.getEscuela})
        }else{
          this.frecuencias = response.data
        }
        this.overlay = false
      }).catch(error=>{
        this.validError( error )
      }).finally(()=> { this.overlay = false })
    },

    // Obtener los ciclos más recientes
    getCiclos() {
      this.overlay = true
      this.ciclos  = []
       this.$http.get('ciclos.activos').then((response) => {
        // Llenamos la etapa incial
        this.ciclos = response.data
        if(this.getdatosUsuario.idpuesto == 18){
          this.ciclos_plantel = this.ciclos.filter(el=> { return el.unidad_negocio == this.getEscuela})
        }else{
          this.ciclos_plantel = response.data
        }
        this.overlay = false
        return
      }).catch(error=>{
        this.validError( error )
      }).finally(()=> { this.overlay = false })
    },

    getVendedoras() {
      this.overlay = true
      this.vendedoras  = []
      return this.$http.get('vendedoras.list').then((response) => {
        // Llenamos la etapa incial
        this.vendedoras = response.data
        this.vendedoras.sort((a,b)=> ( a.nombre - b.nombre ));
        
        if(this.getdatosUsuario.idpuesto == 18){
          this.vendedoras_plantel = this.vendedoras.filter(el=> { return el.escuela == this.getEscuela})
        }


        this.vendedoras_plantel.push({idplantel: 0, plantel:'SIN PLANTEL', nombre:'NA'})
        this.overlay = false
      }).catch(error=>{
        this.validError( error )
      }).finally(()=> { this.overlay = false })
    },

    // Obtener el listado de tareas
    getTareas(){
      this.overlay = true
      this.tareas  = []
      return this.$http.get('tareas.activos.formulario').then((response) => {
        // Llenamos la etapa incial
        this.tareas = response.data
        this.tarea  = this.tareas[0]
        this.overlay = false
      }).catch(error=>{
        this.validError( error )
      }).finally(()=> { this.overlay = false })
    },

    validError( error ){
    	// INdicamos que hay un error en el back
      if(error.body.message){
        this.error = error.body.message
      }else{
        this.error = error.body
      }
      this.errorBack = true
      this.overlay = false
      this.dialogError = true
    },

    validarErrorDirecto( mensaje ){
      this.error = mensaje
      this.errorBack = true
      this.overlay = false
      this.dialogError = true
    }

  }
}