<template>
	<div>
		<div class="text-h6">comentarios | {{ comentarios.length }} |</div>
    <div id="eventos"  style="overflow-y: scroll; overflow-x: hidden; height: 200px" class="mb-4">
      <v-row>
        <v-col v-if="comentarios.length > 0" cols="12">
          <div class="head"
            v-for="(com, i) in comentarios"
            :key="i"
          >
            <small>
              <strong class="user">
                {{ com.nombre_completo }}
              </strong>
                {{ com.fecha_creacion }}
            </small>

            <br/>
            <small>
              <strong class="user">
                Etapa: {{ etapasProspectos.find( el => el.etapa == com.etapa ).text }}
              </strong>
            </small>

            <p>
              {{ com.comentario }}
            </p>
          </div>
          <hr />
        </v-col>
      </v-row>
    </div>
    <v-text-field
      outlined
      rounded
      label="Escribir un comentario"
      v-model="mensaje"
      hide-details
      dense
      :append-icon="mensaje ? 'mdi-send' : ''"
      @click:append="agregarComentario"
    ></v-text-field>

    <!-- Dialog de carga -->
    <carga                v-if="overlay"/>

	</div>
</template>


<script>
import axios from 'axios';

	// Componentes
  import carga                 from '@/components/alertas/carga.vue';

	export default {
		components:{
      carga,
    },

		props:[
			'comentarios',
      'prospecto'
	  ],

	  data: () => ({
	  	mensaje:'',
	  	overlay:false,

      etapasProspectos:[
        { etapa: 0,  text: "Sin contacto"      },
        { etapa: 1,  text: "1era entrevista"   },
        { etapa: 2,  text: "Prueba Técnica"    },
        { etapa: 3,  text: "Prueba psicológica"},
        { etapa: 4,  text: "Entrevista Técnica"},
        { etapa: 5,  text: "Inv. Laboral"      },
        { etapa: 6,  text: "Exámenes Médicos"  },
        { etapa: 7,  text: "Firma de contrato" },
        { etapa: 8,  text: "Inducción"         },
        { etapa: 9,  text: "Capacitación"      },
        { etapa: 10, text: "Incorporación"     },
      ],
	  }),

	  methods:{
	  	agregarComentario( ){
	  		this.overlay = true
	  		this.$emit('click',this.mensaje)
	  		this.mensaje = ''
	  		this.overlay = false
	  	}
	  }
	}
</script>

<style scoped>

  #eventos::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #eventos::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #eventos::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #eventos::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #eventos::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #eventos::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

</style>