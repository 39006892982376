<template>
	<v-dialog
    v-model="paramCerrados.estatus"
    hide-overlay
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title primary-title >
        <v-icon left>mdi-cloud</v-icon> Prospectos Cerrados o Finalizados
      </v-card-title>
      <v-card-text >

      	<v-alert
          border="left"
          colored-border
          type="error"
          elevation="2"
        >
          1.- Los prospectos a continuación son prospectos que
          se encuentran cerrados o finalizados.<br/>
         
        </v-alert>

      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
          <v-text-field
            v-model="search"
            dense
            rounded
            filled
            single-line
            label="Buscar"
            append-icon="mdi-magnify"
          ></v-text-field>  
        </v-col>

        <v-data-table
          :headers="headersCerrados"
          :items="prospectosCerrados"
          class="elevation-0"
          dense
          :search="search"
        >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="activarProspectoCerrado(item.idformulario)"
            color="success"
          >
            mdi-check-outline
          </v-icon>
        </template> 
                    
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"   dark @click="cerrar()">Cancelar</v-btn>
      </v-card-actions>
      <v-btn 
        color="transparent elevation-0"
        x-small
        absolute
        top
        right
        fab
        class="mt-5 mr-0"
        @click="cerrar()"
       ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'paramCerrados',
			'prospectosCerrados'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headersCerrados: [
        { text: 'Folio',        value: 'idformulario'    },
        { text: 'Nombre',       value: 'nombre_completo' },  
        { text: 'Correo',       value: 'correo'          },  
        { text: 'Telefono',     value: 'numero_wa'       },  
        { text: 'Nota Rechazo', value: 'nota_rechazo'    },  
        { text: 'Re-Activar',   value: 'actions'        , sortable: false }, 
      ],
      search:'',
    }),


    methods:{
    	activarProspectoCerrado(idformulario){
        const payload = {
          idformulario: idformulario,
          idusuarioerp: this.getdatosUsuario.iderp
        }

        // Hacer la peticion de los datos
        this.$http.post('formularios.reactivar.cerrado', payload).then((response) => {
          // Ya termino de realizar su función
          for(const i in this.prospectosCerrados){
            if(this.prospectosCerrados[i].idformulario == idformulario){
               this.prospectosCerrados.splice(i,1)
            }
          }

          this.dialogSuccess = true
        }).catch(error=>{
          this.validError( error )
        }).finally(()=> { this.overlay = false })
      },

      cerrar(){
      	this.paramCerrados.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      }
    }
	}
</script>
